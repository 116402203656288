import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config";

const service = new Service();

export const GetListAll = async (
  pageIndex = 1,
  pageSize = config.Configs.DefaultPageSize,
  sort = "",
  owner='',
  district=0,
  contructionType=0,currentStatus=0,lisenceFileType=0,address='',lisenceFileStatus=0
) => {
  const params = new URLSearchParams();
  params.append("pageIndex", pageIndex);
  params.append("pageSize", pageSize);
  sort && params.append("sort", sort);
  address && params.append("address", address);
  owner && params.append("owner", owner);
  district && params.append("districtId", district);
  contructionType && params.append("constructionTypeId", contructionType);
  currentStatus && params.append("lisenceFileCurrentStatusId", currentStatus);
  lisenceFileType && params.append("lisenceFileTypeId", lisenceFileType);
  lisenceFileStatus && params.append("lisenceFileStatusId", lisenceFileStatus);
  try {
    const res = await service.get(ApiUrl.LisenceFileGetListAll, params);
    return res;
  } catch (err) {
    throw err;
  }
};

export const GetDetail = async (id) => {
  const params = new URLSearchParams();
  params.append("id", id);
  try {
    const res = await service.get(
      ApiUrl.LisenceFileGetDetail.replace("{id}", id)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const UpdateLisenceFile = async (body) => {
  try {
    const res = await service.post(ApiUrl.LisenceFileUpdate, body);
    return res;
  } catch (err) {
    throw err;
  }
};

export const DeleteLisenceFile = async (id) => {
  try {
    const res = await service.postParams(
      ApiUrl.LisenceFileDelete.replace("{id}", id)
    );
    return res;
  } catch (err) {
    throw err;
  }
};

export const CreateLisenceFile = (body) => {
  return service
    .post(ApiUrl.LisenceFileCreate, body)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const GetLookupLisenceFile = async () => {
  try {
    const res = await service.get(ApiUrl.LisenceFileGetLookUp);
    return res;
  } catch (error) {
    throw error;
  }
};

export const GetLookupLisenceFileType = () => {
  return service
    .get(ApiUrl.GetLookupLisenceFileType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetLookupLisenceFileStatus = () => {
  return service
    .get(ApiUrl.GetLookupLisenceFileStatus)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetLookupLisenceFileCurrentStatus = () => {
  return service
    .get(ApiUrl.GetLookupLisenceFileCurrentStatus)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetLookupConstructionTier = () => {
  return service
    .get(ApiUrl.GetLookupConstructionTier)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const GetLookupConstructionType = () => {
  return service
    .get(ApiUrl.GetLookupConstructionType)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
export const SearchPlanningByPolygon = (polygon) => {
  const params = new URLSearchParams();
  polygon && params.append('polygon', polygon)
  return service
    .get(ApiUrl.SearchPlanningByPolygon, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};

export const VN2000toWgs84 = (x,y) => {
  const params = new URLSearchParams();
  x && params.append("x", x);
  y && params.append("y", y);
  return service
    .get(ApiUrl.VN2000toWgs84, params)
    .then((res) => {
      return res;
    })
    .catch((err) => {
      throw err;
    });
};
