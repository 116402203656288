import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import Autocomplete from "@material-ui/lab/Autocomplete";
import * as appActions from "../../../../core/app.store";
import * as PlanningAction from "../../../../redux/store/planning/planning.store";
import * as InvestorAction from "../../../../redux/store/investor/investor.store";
import * as ConstructionInvestmentAction from "../../../../redux/store/project-management/constructionInvestment.store";
import * as GidStore from "../../../../redux/store/project-management/gid.store";
import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import FileManagement from "../../../../components/file_management/file_management";
import {
    NotificationMessageType,
    changeAlias,
    APIUrlDefault,
} from "../../../../utils/configuration";
import NotificationService from "../../../../common/notification-service";

function ConstructionInvestmentFormEdit(props) {
    const { showLoading, isOpen, setIsOpen, GetListProject, projectModel, setIsOpenCIDetail, GetListAll } = props;
    const classes = useStyles();
    const [planningDetailId, setPlanningDetailId] = useState(projectModel?.planningDetailId);
    const [planningModel, setPlanningModel] = useState([]);
    const [planning, setPlanning] = useState();
    const [planningSelect, setPlanningSelect] = useState("");
    const [name, setName] = useState("");
    const [investment, setInvestment] = useState();
    const [place, setPlace] = useState();
    const [scale, setScale] = useState();
    const [investorId, setInvestorId] = useState(projectModel?.investorId);
    const [typeId, setTypeId] = useState(projectModel?.typeId);
    const [statusId, setStatusId] = useState(projectModel?.constructionInvestmentStatusId);
    const [order, setOrder] = useState();
    const [type, setType] = useState();
    const [status, setStatus] = useState();
    const [investorSelect, setInvestorSelect] = useState();

    const [investorModel, setInvestorModel] = useState();
    const [statusModel, setStatusModel] = useState();
    const [typeModel, setTypeModel] = useState();
    const [files, setFiles] = useState([projectModel?.avatar]);
    const [filesTemp, setFilesTemp] = useState([]);
    const [isShow, setShow] = useState(false);

    const [fileSelected, setFileSelected] = useState(projectModel?.avatar);
    const [isDocumentShow, setDocumentShow] = useState(false);
    const [documents, setDocuments] = useState(projectModel?.documentFiles);
    const [documentsTemp, setDocumentsTemp] = useState([]);

    const [gallerySelected, setGallerySelected] = useState([]);
    const [isGalleryShow, setGalleryShow] = useState(false);
    const [gallery, setGallery] = useState(projectModel?.galleryFiles);
    const [galleryTemp, setGalleryTemp] = useState([]);


    const { register, handleSubmit, errors, setValue, clearErrors, setError } =
        useForm({
            mode: "all",
            reValidateMode: "onBlur",
        });
    
    useEffect(() => {
        setFiles([projectModel?.avatar]);
        setFileSelected(projectModel?.documentFiles);
        setDocuments(projectModel?.documentFiles);
        setGallery(projectModel?.galleryFiles)
        setGallerySelected(projectModel?.galleryFiles)
    },[projectModel])

    useEffect(() => {
        if (projectModel?.typeId && typeModel) {
            setType(typeModel.find((x) => x.id === projectModel.typeId))
        }
    }, [projectModel, typeModel])
    
    useEffect(() => {
        if (projectModel?.planningDetailId && planningModel) {
            setPlanning(planningModel.find((x) => x.id === projectModel.planningDetailId))
        }
    }, [projectModel, planningModel])

    useEffect(() => {
        if (projectModel?.constructionInvestmentStatusId && statusModel) {
            setStatus(statusModel.find((x) => x.id === projectModel.constructionInvestmentStatusId))
        }
    }, [projectModel, statusModel])
    useEffect(() => {
        if (projectModel?.investorId && investorModel) {
            setInvestorSelect(investorModel.find((x) => x.id === projectModel.investorId))
        }
    }, [projectModel, investorModel])
    
    const onSubmit = (data) => {
        if (!data) {
            return;
        } else {
            console.log(data);
            console.log('planning',planning);
            console.log('planningDetailId',planningDetailId);
            let formData = new FormData;
            formData.append('id', projectModel?.id)
            formData.append('Name', data?.name)
            formData.append('Investment', data?.Investment)
            formData.append('Scale', data?.Scale)
            formData.append('Note', data?.note)
            formData.append('Place', data?.Place)
            formData.append('TypeId', typeId? typeId: projectModel.typeId)
            formData.append('ConstructionInvestmentStatusId', status? status.id: projectModel.constructionInvestmentStatusId)
            formData.append('InvestorId', investorSelect? investorSelect.id: projectModel.investorId)
            formData.append('Order', data?.order)
            formData.append('PlanningDetailId', planning? planning.id: 0)
            formData.append('TableName', projectModel.tableName)
            formData.append('Gid', projectModel.gid)
            formData.append('DocumentUploadId', files[0].fileId)
            formData.append('Code', `${projectModel.tableName}.${projectModel.gid}`)
            fileSelected.length > 0 ? fileSelected.map(item => {
                formData.append('Documents', item.fileId)
            }): formData.append('Documents', 0)
            gallerySelected.length > 0 ? gallerySelected.map(item => {
                formData.append('Gallerys', item.fileId)
            }): formData.append('Gallerys', 0)
            ConstructionInvestmentAction.UpdateConstructionInvestment(formData).then(res => {
                console.log('ok');
                GetListProject(projectModel.gid,projectModel.tableName);
                setIsOpen(false);
                GetListAll();
                setIsOpenCIDetail(false)
                NotificationService.success("Cập nhật thành công");
            })
        }
    }

    const GetLookupInvestor = () => {
        InvestorAction.GetLookUpInvestor().then((res) => {
            setInvestorModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }

    const GetLookupStatus = () => {
        ConstructionInvestmentAction.GetLookupStatus().then((res) => {
            setStatusModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }

    const GetLookupType = () => {
        ConstructionInvestmentAction.GetLookupType().then((res) => {
            setTypeModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }

    const GetLookupPlanning = () => {
        PlanningAction.GetLookUpPlanning().then((res) => {
            setPlanningModel(res && res.content ? res.content : [])
        }).catch(err => console.log(err))
    }
    useEffect(() => {
        GetLookupInvestor();
        GetLookupPlanning();
        GetLookupStatus();
        GetLookupType();
    }, [])

    useEffect(() => {
        console.log('sssssssssss',projectModel)
    }, [projectModel])

    const onOpenSelectFile = () => {
        setShow(true);
        setFilesTemp(files);
    };

    const onCloseSelectFile = () => {
        setShow(false);
        setFiles(filesTemp);
    };

    const onSaveSelectFile = () => {
        setShow(false);
    };

    const onOpenSelectDocument = () => {
        setDocumentShow(true);
        setDocumentsTemp(documents);
    };
    const onCloseSelectDocument = () => {
        setDocumentShow(false);
        setDocuments(documentsTemp);
    };
    const onSaveSelectDocument = () => {
        setDocumentShow(false);
        setFileSelected([...fileSelected, ...documents])
        console.log([...documents])
    };
    const onOpenSelectGallery = () => {
        setGalleryShow(true);
        setGalleryTemp(gallery);
    };
    const onCloseSelectGallery = () => {
        setGalleryShow(false);
        setGallery(galleryTemp);
    };
    const onSaveSelectGallery = () => {
        setGalleryShow(false);
        setGallerySelected([...gallerySelected, ...gallery])
        console.log([...gallery])
    };
    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                onClose={() =>
                    setIsOpen(false)
                }
                aria-labelledby="ConstructionInvestmentForm"
                aria-describedby="ConstructionInvestmentForm_container"
                id="ConstructionInvestmentForm"
                open={isOpen}
            >
                <DialogTitle id="ConstructionInvestmentForm">
                    Chỉnh sửa thông tin dự án
                </DialogTitle>
                <DialogContent
                    className="ConstructionInvestmentForm_container"
                    id="ConstructionInvestmentForm_container"
                    dividers
                >
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Tên dự án<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    type="text"
                                    name="name"
                                    defaultValue={projectModel?.name}
                                    error={
                                        (errors.name &&
                                            errors.name.type === "required") ||
                                        (errors.name &&
                                            errors.name.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.name &&
                                    errors.name.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.name &&
                                    errors.name.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Quỹ đầu tư<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    type="text"
                                    defaultValue={projectModel?.investment}
                                    name="Investment"
                                    error={
                                        (errors.Investment &&
                                            errors.Investment.type === "required") ||
                                        (errors.Investment &&
                                            errors.Investment.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.Investment &&
                                    errors.Investment.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.Investment &&
                                    errors.Investment.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Quy mô<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    defaultValue={projectModel?.scale}
                                    type="text"
                                    name="Scale"
                                    error={
                                        (errors.Scale &&
                                            errors.Scale.type === "required") ||
                                        (errors.Scale &&
                                            errors.Scale.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.Scale &&
                                    errors.Scale.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.Scale &&
                                    errors.Scale.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Địa chỉ<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 300 })}
                                    type="text"
                                    defaultValue={projectModel?.place}
                                    name="Place"
                                    error={
                                        (errors.Place &&
                                            errors.Place.type === "required") ||
                                        (errors.Place &&
                                            errors.Place.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.Place &&
                                    errors.Place.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.Place &&
                                    errors.Place.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Loại dự án
                                    <span className="required"></span>
                                </label>
                                
                                <Autocomplete
                                    id="typeId"
                                    options={typeModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={type}
                                    onChange={(event, newValue) => {
                                        setTypeId(newValue.id);
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="typeId"
                                            inputRef={register({ required: true })}
                                            error={
                                                errors.typeId &&
                                                errors.typeId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.typeId &&
                                    errors.typeId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Trạng thái
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={statusModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={status}
                                    onChange={(event, newValue) => {
                                        setStatus(newValue);
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="statusId"
                                            inputRef={register({ required: true })}
                                            error={
                                                errors.statusId &&
                                                errors.statusId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.statusId &&
                                    errors.statusId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Chủ đầu tư
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={investorModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={investorSelect}
                                    onChange={(event, newValue) => {
                                        setInvestorSelect(newValue);
                                        
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="investorId"
                                            inputRef={register({ required: true })}
                                            error={
                                                errors.investorId &&
                                                errors.investorId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.investorId &&
                                    errors.investorId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Sắp xếp<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({ required: true, maxLength: 50 })}
                                    type="number"
                                    defaultValue={projectModel?.order}
                                    name="order"
                                    error={
                                        (errors.order &&
                                            errors.order.type === "required") ||
                                        (errors.order &&
                                            errors.order.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{
                                        maxLength: 50,
                                        pattern: "[0-9]",
                                        inputMode: "decimal",
                                        min: 0,
                                        step: 1,
                                    }}
                                />
                                {errors.order &&
                                    errors.order.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.order &&
                                    errors.order.type === "maxLength" && (
                                        <span className="error">Tối đa 50 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">Mô tả</label>
                                <textarea
                                    name="note"
                                    defaultValue={projectModel?.note}
                                    rows="5"
                                    className="form-control"
                                    ref={register}
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">
                                    Quy hoạch chi tiết                                 
                                </label>
                                <Autocomplete
                                    options={planningModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={planning}
                                    onChange={(event, newValue) => {
                                        setPlanningDetailId(newValue?.id);
                                        setPlanning(newValue);
                                        setValue("planningDetailId", newValue?.id)
                                        clearErrors("planningDetailId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="planningDetailId"
                                            {...register('planningDetailId')}

                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="text-dark">File đính kèm</label>
                            <div className="list__img d-flex flex-wrap mt-1">
                                {!isShow &&
                                    fileSelected &&
                                    fileSelected.length > 0 &&
                                    fileSelected.map((item) => (
                                        <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                            <img
                                                src={require("../../../../assets/icon/default.svg")}
                                                alt={item.fileName}
                                                title={item.fileName}
                                                className="img-fluid mb-2"
                                                style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                            <p className="file_name">{item.fileName}</p>
                                            <p
                                                className="close_x"
                                                onClick={() =>
                                                    setFileSelected(fileSelected.filter(i => i !== item))
                                                }
                                            >
                                                &#10005;
                                            </p>
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onOpenSelectDocument}
                                >
                                    Chọn file
                                </Button>
                            </div>
                        </div>
                        <div className="form-group">
                            <label className="text-dark">Thư viện ảnh</label>
                            <div className="list__img d-flex flex-wrap mt-1">
                                {!isGalleryShow &&
                                    gallerySelected &&
                                    gallerySelected.length > 0 &&
                                    gallerySelected.map((item) => (
                                        <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                            <img
                                                src={require("../../../../assets/icon/default.svg")}
                                                alt={item.fileName}
                                                title={item.fileName}
                                                className="img-fluid mb-2"
                                                style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                            <p className="file_name">{item.fileName}</p>
                                            <p
                                                className="close_x"
                                                onClick={() =>
                                                    setGallerySelected(gallerySelected.filter(i => i !== item))
                                                }
                                            >
                                                &#10005;
                                            </p>
                                        </div>
                                    ))}
                            </div>
                            <div>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    onClick={onOpenSelectGallery}
                                >
                                    Chọn file
                                </Button>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">
                                    Ảnh<span className="required"></span>
                                </label>
                                {!isShow &&
                                    files &&
                                    files.length > 0 &&
                                    files.map((item) => (
                                        <div key={item?.fileName} style={{ width: "150px" }}>
                                            <img
                                                src={APIUrlDefault + item?.filePreview}
                                                alt={item?.fileName}
                                                className="img-fluid mb-2"
                                                style={{
                                                    width: "auto",
                                                    height: "auto",
                                                    maxWidth: "100%",
                                                    maxHeight: "100%",
                                                }}
                                            />
                                        </div>
                                    ))}
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onOpenSelectFile}
                                    >
                                        Chọn file
                                    </Button>
                                    <TextField
                                        inputRef={register({ required: true })}
                                        type="hidden"
                                        name="image"
                                        value={(files && files.length > 0 && files[0]?.fileName) || ""}
                                    />
                                    {errors.image && errors.image.type === "required" && (
                                        <p className="error">Trường này là bắt buộc</p>
                                    )}
                                </div>
                            </div>
                        </div>
                        <DialogActions>
                            <Button
                                type="button"
                                onClick={() => setIsOpen(false)}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="submit"
                                style={{ marginLeft: "20px" }}
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>


            </Dialog>
            {isShow && (
                <Dialog
                    onClose={onCloseSelectFile}
                    open={isShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectFile}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            files={files}
                            setFiles={setFiles}
                            acceptedFiles={["jpeg", "png", "jpg", "gif"]}
                            filterExtension={"png"}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectFile}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Thoát
                        </Button>
                        {files && files.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectFile}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            {isDocumentShow && (
                <Dialog
                    onClose={onCloseSelectDocument}
                    open={isDocumentShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectDocument}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={documents}
                            fileSelected={fileSelected}
                            setFiles={setDocuments}
                            acceptedFiles={["doc", "docx", "txt", "pdf", "png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectDocument}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {documents && documents.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectDocument}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            {isGalleryShow && (
                <Dialog
                    onClose={onCloseSelectGallery}
                    open={isGalleryShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectGallery}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={gallery}
                            fileSelected={gallerySelected}
                            setFiles={setGallery}
                            acceptedFiles={["png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectGallery}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {gallery && gallery.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectGallery}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
        </>
    )
}

const useStyles = makeStyles((theme) => ({
    closeButton: {
        position: "absolute",
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
    },
}));

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConstructionInvestmentFormEdit);