import React, { useState, useEffect } from "react";
import { Configs } from "../../common/config";

import { Button } from "@material-ui/core";
import AddCircle from "@material-ui/icons/AddCircle";

import ListLisenceFile from "./list-lisence-file/list-lisence-file.view";
import DeleteDialog from "../../components/dialog-delete/dialog-delete.view";
import EditPlanningUnit from "./edit-lisence-file/edit-lisence-file.view";
import { useHistory } from "react-router-dom";
import * as districtAction from "../../redux/store/district-management/district.store";
import ShowNotification from "../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../utils/configuration";
import * as viVN from "../../language/vi-VN.json";
import { UrlCollection } from "../../common/url-collection";
import * as apiAction from "../../redux/store/lisence-file/lisence-file.store";
import SearchLisenceFile from "./search-lisence-file/search-lisence-file.view";
const LisenceFile = () => {
  const [page, setPage] = useState(0);
  const [data, setData] = useState();
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [totalItemCount, setTotalItemCount] = useState();
  const [openAddDialog, setOpenAddDialog] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [planningId, setPlanningId] = useState();
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);

  const [owner,setOwner] = useState();
  const [address,setAddress] = useState();
  const [district,setDistrict] = useState(null);
  const [contructionType,setContructionType] = useState(null);
  const [currentStatus,setCurrentStatus] = useState(null);
  const [lisenceFileStatus,setLisenceFileStatus] = useState(null); 
  const [lisenceFileType,setLisenceFileType] = useState(null);
  const [districtModel, setDistrictModel] = useState([]);
  const [lisenceFileTypeModel, setLisenceFileTypeModel] = useState([]);
  const [lisenceFileCurrentStatusModel, setLisenceFileCurrentStatusModel] = useState([]);
  const [lisenceFileStatusModel, setLisenceFileStatusModel] = useState([]);
  const [constructionTypeModel, setConstructionTypeModel] = useState([]);
  const [constructionTierModel, setConstructionTierModel] = useState([]);
  const history = useHistory();
  useEffect(() => {
    GetListAll();
    GetLookupDistrict();
    GetLookupLisenceFileType();
    GetLookupLisenceFileStatus();
    GetLookupLisenceFileCurrentStatus();
    GetLookupConstructionTier();
    GetLookupConstructionType();
  }, []);
const GetLookupDistrict = () => {
    districtAction
        .GetLookupDistrict()
        .then((res) => {
            if (res && res.content) {
                setDistrictModel(res.content);
            }
        })
        .catch((err) => {
        });
}
const GetLookupLisenceFileType = () => { 
    apiAction
      .GetLookupLisenceFileType()
      .then((res) => {
          if (res && res.content) {
            setLisenceFileTypeModel(res.content);
          }
      })
      .catch((err) => {
      });
}
const GetLookupLisenceFileStatus = () => { 
  apiAction
    .GetLookupLisenceFileStatus()
    .then((res) => {
        if (res && res.content) {
          setLisenceFileStatusModel(res.content);
        }
    })
    .catch((err) => {
    });
}
const GetLookupLisenceFileCurrentStatus = () => { 
  apiAction
    .GetLookupLisenceFileCurrentStatus()
    .then((res) => {
        if (res && res.content) {
          setLisenceFileCurrentStatusModel(res.content);
        }
    })
    .catch((err) => {
    });
}
const GetLookupConstructionTier = () => { 
  apiAction
    .GetLookupConstructionTier()
    .then((res) => {
        if (res && res.content) {
          setConstructionTierModel(res.content);
        }
    })
    .catch((err) => {
    });
}
const GetLookupConstructionType = () => { 
  apiAction
    .GetLookupConstructionType()
    .then((res) => {
        if (res && res.content) {
          setConstructionTypeModel(res.content);
        }
    })
    .catch((err) => {
    });
}
  const GetListAll = (pageIndex = 1, pageSize, sort,owner,district,contructionType,currentStatus,lisenceFileType,address,lisenceFileStatus) => {
    setPage(pageIndex - 1);
    apiAction
      .GetListAll(
        pageIndex, pageSize, sort,owner,district?.id,
        contructionType?.id,currentStatus?.id,lisenceFileType?.id
        ,address,lisenceFileStatus?.id)
      .then((res) => {
        if (res && res.content) {
          setData(res.content.items);
          setTotalItemCount(res.content.totalItemCount);
        }
      })
      .catch((err) => ShowNotification(
        viVN.Errors.AccessDenied,
        NotificationMessageType.Error
      ));
  };
  const handleDelete = () => {
    apiAction.DeleteLisenceFile(planningId).then(
      (res) => {
        if (res && res.content && res.content.status) {
          GetListAll(1, rowsPerPage, orderBy + " " + order);
          handleCloseDeleteDialog();
          ShowNotification(
            viVN.Success.PlaningUnitDeleteSuccess,
            NotificationMessageType.Success
          );
        }
      },
      (err) => {
        err &&
          err.errorType &&
          ShowNotification(
            err.errorMessage,
            NotificationMessageType.Error
          );
          handleCloseDeleteDialog();
      }
    );
  };
  const handleCloseAddDialog = () => {
    setOpenAddDialog(false);
  };

  const handleOpenEditDialog = (planningId) => {
    setPlanningId(planningId);
    setOpenEditDialog(true);
  };
  const viewMapAction = (planningId) => {
    history.push(UrlCollection.CPXD)
  };
  
  const handleCloseEditDialog = () => {
    setOpenEditDialog(false);
  };

  const handleOpenDeleteDialog = (planningId) => {
    setPlanningId(planningId);
    setOpenDeleteDialog(true);
  };

  const handleCloseDeleteDialog = () => {
    setOpenDeleteDialog(false);
  };
  const onSubmit = async (data) => {
    await GetListAll(1, rowsPerPage, orderBy + " " + order,owner,district,
    contructionType,currentStatus,lisenceFileType
    ,address,lisenceFileStatus);
  };
  const refresh = () => {
    GetListAll(1, rowsPerPage, orderBy + " " + order,owner,district,
    contructionType,currentStatus,lisenceFileType
    ,address,lisenceFileStatus);
  };
  return (
    <div className="slider">
      <div className="d-sm-flex align-items-center justify-content-between mb-3">
        <div></div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            history.push(UrlCollection.AddLisenceFile);
          }}
          startIcon={<AddCircle />}
        >
          Thêm Cấp phép xây dựng
        </Button>
      </div>
      
      <SearchLisenceFile
        owner = {owner}
        district = {district}
        contructionType = {contructionType}
        currentStatus = {currentStatus}
        lisenceFileType = {lisenceFileType}
        address = {address}
        lisenceFileStatus = {lisenceFileStatus}
        setDistrict={setDistrict}
        setOwner={setOwner}
        setContructionType={setContructionType}
        setCurrentStatus={setCurrentStatus}
        setLisenceFileStatus={setLisenceFileStatus}
        setAddress={setAddress}
        setLisenceFileType={setLisenceFileType}
        districtModel={districtModel}
        lisenceFileTypeModel= {lisenceFileTypeModel}
        lisenceFileCurrentStatusModel = {lisenceFileCurrentStatusModel}
        lisenceFileStatusModel={lisenceFileStatusModel}
        constructionTypeModel={constructionTypeModel}
        constructionTierModel={constructionTierModel}
        refresh={refresh}
        onSubmit={onSubmit}
      />
      {data && (
        <ListLisenceFile
          totalItemCount={totalItemCount}
          data={data}
          districtModel={districtModel}
          GetListAll={GetListAll}
          editAction={handleOpenEditDialog}
          deleteAction={handleOpenDeleteDialog}
          setPage={setPage}
          setRowsPerPage={setRowsPerPage}
          page={page}
          rowsPerPage={rowsPerPage}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          viewMapAction={viewMapAction}
        />
      )}
      {openEditDialog && (
        <EditPlanningUnit
          isOpen={openEditDialog}
          onClose={handleCloseEditDialog}
          onSuccess={handleCloseEditDialog}
          planningId={planningId}
          GetListAll={GetListAll}
          rowsPerPage={rowsPerPage}
          setOrder={setOrder}
          setOrderBy={setOrderBy}
          districtModel={districtModel}
          lisenceFileTypeModel= {lisenceFileTypeModel}
          lisenceFileCurrentStatusModel = {lisenceFileCurrentStatusModel}
          lisenceFileStatusModel={lisenceFileStatusModel}
          constructionTypeModel={constructionTypeModel}
          constructionTierModel={constructionTierModel}
        />
      )}

      {openDeleteDialog && (
        <DeleteDialog
          isOpen={openDeleteDialog}
          onClose={handleCloseDeleteDialog}
          onSuccess={handleDelete}
          header={"Xác nhận xóa"}
          content={"Bạn có chắc chắn muốn xóa?"}
        />
      )}
    </div>
  );
};

export default LisenceFile;
