/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link, useLocation } from "react-router-dom";
import { UrlCollection } from "../../common/url-collection";
import LinkAdministratorItems from "./child-components/link-adminstrator-items.view";
import LinkSubMenu from "./child-components/link-sub-items.view";
import { useHistory } from "react-router-dom";

//--- Material Icon
import HomeIcon from "@material-ui/icons/Home";
import PostAddIcon from "@material-ui/icons/PostAdd";
import AssignmentIcon from "@material-ui/icons/Assignment";
import SettingsIcon from "@material-ui/icons/Settings";
//--- Font Awesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";

import * as clientSettingAction from "../../redux/store/client_setting/client_setting.store";
import * as appActions from "../../core/app.store";

//--- Style
import "./sidebar.scss";

function Sidebar(props) {
  const {
    settings,
    getSettings,
    isCollapsed,
    expandSidebar,
    collapseSidebar,
    isDirty,
  } = props;
  const history = useHistory();
  const isMobile = window.innerWidth < 1281;

  const [isSubMenuOpen, setSubMenuOpen] = useState(false);
  const [openQLQH, setOpenQLQH] = useState(false);
  const [openQHHTKT, setOpenQHHTKT] = useState(false);
  const [openQHCC, setOpenQHCC] = useState(false);
  const [openHSCP, setOpenHSCP] = useState(false);
  const [openDADT, setOpenDADT] = useState(false);
  useEffect(() => {
    getSettings();
  }, [getSettings]);

  useEffect(() => {
    setClientSetting(settings);
  }, [settings]);

  useEffect(() => {
    if (isMobile) {
      collapseSidebar();
    }
  }, [collapseSidebar, isMobile]);

  const [clientSetting, setClientSetting] = useState();
  const currentLocation = useLocation();

  const onMouseEnter = () => {
    (isDirty || isMobile) && isCollapsed && expandSidebar();
  };

  const onMouseLeave = () => {
    (isDirty || isMobile) && collapseSidebar();
  };

  const currentIsHomePage =
    currentLocation.pathname === "/" ||
    currentLocation.pathname === UrlCollection.Dashboard;
  const currentIsPlanningAnnouncementProcessPage =
    currentLocation.pathname.includes(
      UrlCollection.PlanningAnnouncementProcess
    );
  const currentIsPlanningCCPage =
    currentLocation.pathname.includes(
      UrlCollection.PlanningCC
    );
  const currentIsSliderPage = currentLocation.pathname.includes(
    UrlCollection.Slider
  );
  const currentIsMapDataPage = currentLocation.pathname.includes(
    UrlCollection.MapData
  );
  const currentIsNewsPage = currentLocation.pathname.includes(
    UrlCollection.News
  );
  const currentIsDocumentManagementPage = currentLocation.pathname.includes(
    UrlCollection.DocumentManagement
  );
  const currentIsPTQD = currentLocation.pathname.includes(UrlCollection.PTQD);
  const currentIsQTHT = currentLocation.pathname.includes(UrlCollection.QTHT);
  const currentIsQHHTKT = currentLocation.pathname.includes(UrlCollection.QH_HTKT);
  const currentIsDADT = 
  currentLocation.pathname.includes(
    UrlCollection.ProjectManagement
  ) || currentLocation.pathname.includes(
    UrlCollection.ProjectMap
  );
  const currentIsOtherPlanningPage =
    currentLocation.pathname.includes(
      UrlCollection.ProjectManagement
    );
  const currentIsProjectManagePage =
    currentLocation.pathname.includes(
      UrlCollection.ProjectManagement
    );

  const currentIsLisenceFilePage =
    currentLocation.pathname.includes(
      UrlCollection.AddLisenceFile
    ) || currentLocation.pathname.includes(
      UrlCollection.LisenceFile
    ) || currentLocation.pathname.includes(
      UrlCollection.CPXD
    );

  const currentIsAdministratorPages =
    !currentIsHomePage &&
    !currentIsPlanningAnnouncementProcessPage &&
    !currentIsPlanningCCPage &&
    !currentIsSliderPage &&
    !currentIsMapDataPage &&
    !currentIsNewsPage &&
    !currentIsDocumentManagementPage &&
    !currentIsPTQD &&
    !currentIsQTHT &&
    !currentIsQHHTKT &&
    !currentIsDADT &&
    !currentIsOtherPlanningPage &&
    !currentIsLisenceFilePage &&
    !currentIsOtherPlanningPage;

  const isShowQHDT = true;

  const isShowHeThong = true;
  const isShowPTQD = true;
  // const isShowPTQD = currentLocation.pathname.includes("/ptqd");

  useEffect(() => {
    setSubMenuOpen(currentIsAdministratorPages);
  }, [currentIsAdministratorPages]);

  useEffect(() => {
    setOpenQLQH(currentIsPlanningAnnouncementProcessPage);
  }, [currentIsPlanningAnnouncementProcessPage]);

  useEffect(() => {
    setOpenQHCC(currentIsLisenceFilePage);
  }, [currentIsLisenceFilePage]);

  // useEffect(() => {
  //   setOpenQHCC(currentIsPlanningCCPage);
  // }, [currentIsPlanningCCPage]);

  useEffect(() => {
    setOpenQHHTKT(currentIsQHHTKT);
  }, [currentIsQHHTKT]);
  useEffect(() => {
    setOpenDADT(currentIsDADT);
  }, [currentIsDADT]);
  return clientSetting ? (
    <>
      <div
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        className={"aside" + (isCollapsed ? " toggled" : "")}
      >
        <ul
          className={
            "navbar-nav bg-gradient-primary sidebar sidebar-dark accordion aside__menu" +
            (isCollapsed ? " toggled" : "")
          }
          id="accordionSidebar"
        >
          <hr className="sidebar-divider my-0" />

          {isShowQHDT && (
            <>
              <li className="nav-item">
                <Link
                  className={`nav-link ${currentIsHomePage && "is-active"}`}
                  to="/qhdt/dashboard"
                  onClick={() => setSubMenuOpen(false)}
                >
                  <HomeIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Dashboard</span>}
                </Link>
              </li>
              <hr className="sidebar-divider mb-0" />
              <li
                className={`nav-item ${openQLQH && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsPlanningAnnouncementProcessPage && "is-active"
                    }`}
                  to={UrlCollection.PlanningAnnouncementProcess}
                  onClick={() => {
                    history.push(UrlCollection.PlanningAnnouncementProcess);
                    setOpenQLQH(!openQLQH);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quản lý quy hoạch xây dựng</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.LinkMapProcess}
                      title="Bản đồ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.ConsultTheCommunity}
                      title="Xin ý kiến"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.PlanningRelate}
                      title="Quy hoạch liên quan"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.DocumentSetting}
                      title="Thiết lập hồ sơ"
                    />
                  </li>

                </ul>
              </li>
              <li
                className={`nav-item ${openQHCC && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsLisenceFilePage && "is-active"
                    }`}
                  to={UrlCollection.LisenceFile}
                  onClick={() => {
                    history.push(UrlCollection.LisenceFile);
                    setOpenQHCC(!openQHCC);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quản lý cấp phép xây dựng</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.LisenceFile}
                      title="Danh sách cấp phép xây dựng"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.AddLisenceFile}
                      title="Thêm mới cấp phép xây dựng"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.CPXD}
                      title="Bản đồ cấp phép xây dựng"
                    />
                  </li>
                </ul>
              </li>
              <li
                className={`nav-item ${openQHHTKT && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsQHHTKT && "is-active"}`}
                  to={UrlCollection.QH_HTKT}
                  onClick={() => {
                    history.push(UrlCollection.QH_HTKT);
                    setOpenQHHTKT(!openQHHTKT);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quản lý hạ tầng kỹ thuật</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_LINK_MAP}
                      title="Bản đồ"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_CONSULT}
                      title="Xin ý kiến"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_Relate}
                      title="Quy hoạch liên quan"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.QH_HTKT_SETUP_DOCUMENT}
                      title="Thiết lập hồ sơ"
                    />
                  </li>

                </ul>
              </li>
              {/* <li className="nav-item">
                <Link
                  className={`nav-link ${currentIsDADT && "is-active"}`}
                  to={UrlCollection.ProjectManagement}
                  onClick={() => setSubMenuOpen(false)}
                >
                  <PostAddIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quản lý dự án đầu tư xây dựng</span>}
                </Link>
              </li> */}

              <li
                className={`nav-item ${openDADT && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsDADT && "is-active"
                    }`}
                  to={UrlCollection.ProjectManagement}
                  onClick={() => {
                    history.push(UrlCollection.ProjectManagement);
                    setOpenDADT(!openDADT);
                  }}
                >
                  <AssignmentIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Quản lý dự án đầu tư xây dựng</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.ProjectManagement}
                      title="Danh sách dự án"
                    />
                  </li>
                  <li>
                    <LinkSubMenu
                      pageLink={UrlCollection.MapProjectManagement}
                      title="Bản đồ"
                    />
                  </li>
                </ul>
              </li>
            </>
          )}
          <li className="nav-item">
            <Link
              className={`nav-link ${currentIsSliderPage && "is-active"}`}

              to={{ pathname: UrlCollection.Slider }}
            >
              <PostAddIcon fontSize="small" className="mr-2" />
              {!isCollapsed && <span>Slide</span>}
            </Link>
          </li>


          <li className="nav-item">
            <Link
              className={`nav-link ${currentIsNewsPage && "is-active"}`}
              to={UrlCollection.News}
              onClick={() => setSubMenuOpen(false)}
            >
              <PostAddIcon fontSize="small" className="mr-2" />
              {!isCollapsed && <span>Tin tức</span>}
            </Link>
          </li>

          {isShowHeThong && (
            <>
              <li
                className={`nav-item ${isSubMenuOpen && !isCollapsed ? "is-open" : ""
                  }`}
              >
                <a
                  className={`nav-link ${currentIsAdministratorPages && "is-active"
                    }`}
                  onClick={() => {
                    //history.push(UrlCollection.EmailTemplate);
                    setSubMenuOpen(!isSubMenuOpen);
                  }}
                >
                  <SettingsIcon fontSize="small" className="mr-2" />
                  {!isCollapsed && <span>Administrator</span>}
                  {!isCollapsed && (
                    <>
                      <FontAwesomeIcon
                        icon={faChevronRight}
                        className="float-right mt-1 chevron"
                      />
                      <FontAwesomeIcon
                        icon={faChevronDown}
                        className="float-right mt-1 chevron"
                      />
                    </>
                  )}
                </a>
                <Link></Link>
                <ul className="aside__menu-sub">
                  <li>
                    <LinkAdministratorItems
                      // icon={EmailIcon}
                      pageLink={UrlCollection.EmailTemplate}
                      title="Email Template"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      // icon={AllInboxIcon}
                      pageLink={UrlCollection.EmailGenerated}
                      title="Khởi tạo email"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      // icon={ContactPhoneIcon}
                      pageLink={UrlCollection.ContactManagement}
                      title="Liên hệ"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={PeopleIcon}
                      pageLink={UrlCollection.RoleManagement}
                      title="Chức vụ"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={AccountBoxIcon}
                      pageLink={UrlCollection.Log}
                      title="Quản lý log"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={AccountBoxIcon}
                      pageLink={UrlCollection.UserManagement}
                      title="Quản lý người dùng"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/commune.png")} alt="commune" />}
                      pageLink={UrlCollection.CommuneManagement}
                      title="Quản lý xã/phường"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/district.png")} alt="district" />}
                      pageLink={UrlCollection.DistrictManagement}
                      title="Quản lý quận/huyện"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/province.png")} alt="province" />}
                      pageLink={UrlCollection.ProvinceManagement}
                      title="Quản lý tỉnh/thành phố"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/group-links.png")} alt="group-links" />}
                      pageLink={UrlCollection.LinkGroup}
                      title="Danh sách nhóm"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //   icon={() => <img className="mr-2 mt-n1" src={require("../../assets/icon/link.png")} alt="link" />}
                      pageLink={UrlCollection.ServiceLink}
                      title="Danh sách liên kết"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      //  icon={HistoryIcon}
                      pageLink={UrlCollection.UserLogHistory}
                      title="Nhật ký người dùng"
                    />
                  </li>
                  {/* <li>
                <LinkAdministratorItems
                  // icon={ViewComfyIcon}
                  pageLink={UrlCollection.LandType}
                  title="Quản lý loại đất"
                />
              </li> */}
                  {/* <li>
                <LinkAdministratorItems
                  // icon={ViewAgendaIcon}
                  pageLink={UrlCollection.LandTypeDetail}
                  title="Quản lý chi tiết loại đất"
                />
              </li> */}
                  <li>
                    <LinkAdministratorItems
                      //  icon={CallToActionIcon}
                      pageLink={UrlCollection.OpinionForm}
                      title="Tạo form ý kiến"
                    />
                  </li>
                  {/* <li>
                <LinkAdministratorItems
                  //  icon={TableChartIcon}
                  pageLink={UrlCollection.TableStructure}
                  title="Sửa cấu trúc bảng"
                />
              </li> */}
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.PlanningUnit}
                      title="Đơn vị lập quy hoạch"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.Investor}
                      title="Chủ đầu tư"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.ApprovalAgency}
                      title="Cơ quan phê duyệt"
                    />
                  </li>
                  <li>
                    <LinkAdministratorItems
                      pageLink={UrlCollection.Slider}
                      title="Slider"
                    />
                  </li>
                  <li className="nav-item"></li>
                </ul>
              </li>
            </>
          )}

          <hr className="sidebar-divider d-none d-md-block" />
        </ul>

        <div className="d-flex flex-column sidebar sidebar-dark w-100">
          <div class="copyright">
            <p>{clientSetting?.copyright}</p>
          </div>
        </div>
      </div>
    </>
  ) : null;
}

const mapStateToProps = (state) => ({
  settings: state.clientSetting.clientSetting,
  isCollapsed: state.app.isCollapsed,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      getSettings: clientSettingAction.getSettings,
      expandSidebar: appActions.ExpandSidebar,
      collapseSidebar: appActions.CollapseSidebar,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
