import React, { useState, useEffect } from "react";
import { Configs } from "../../../common/config";

import AddCircle from "@material-ui/icons/AddCircle";
import Select from "react-select";
import ListProjectManagement from "./list-project-management.view";
import Pagination from '@material-ui/lab/Pagination';
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import * as appActions from "../../../core/app.store";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import * as apiAction from "../../../redux/store/project-management/constructionInvestment.store.js";
import * as GidStore from "../../../redux/store/project-management/gid.store";
import * as ApiConfig from "../../../api/api-config";
import ClickOutside from "../../../components/click-outside/click-outside";
import {
  Button,
  IconButton,
  Tooltip,
  Paper,
  InputBase,
  Divider,
} from "@material-ui/core";
import { Menu, Refresh } from "@material-ui/icons";
import SearchIcon from "@material-ui/icons/Search";
import ControlFunctionOpenlayerType from "../../../components/open-layer/control-function-type/control-function-type";
import "./list-project.scss";

const ListProject = (props) => {
  const { showLoading,
    setCoordinate,
    setGid,
    setTableName,
    setIsOpenInfo,
    setProjectName,
    setListProject,
    listProject,
    page,
    setPage,
    totalItemCount,
    totalPage,
    handleChangePage,
    handleSelectSort,
    GetListAll,
    input, setInput,
    sort,
  } = props;
  const [data, setData] = useState();
  const [orderBy, setOrderBy] = useState("modifiedDate");
  const [rowsPerPage, setRowsPerPage] = useState(Configs.DefaultPageSize);
  const [projectSelect, setProjectSelect] = useState();
  const [isOpenSearch, setIsOpenSearch] = useState();
  const [options, setOptions] = useState('');
  const [typeId, setTypeId] = useState(0);
  const [type,setType] = useState();
  const [typeModel, setTypeModel] = useState();
  const [place, setPlace] = useState('');

  const pageSize = 10;

  const Options = [
    { value: 'modifiedDate desc', label: 'Mới nhất' },
    { value: 'modifiedDate asc', label: 'Cũ nhất' },
  ]
  const GetLookupType = () => {
    showLoading(true)
    apiAction.GetLookupType().then((res) => {
      if (res && res.content) {
        let data = res.content.map((item) => {
          return { ...item, label: item.name, value: item.id };
        })
        setOptions([{ label: 'Tất cả', value: 0 }, ...data])
        setTypeModel(res.content)
      }

      showLoading(false)
    }).catch(err => console.log(err))
  }
  useEffect(() => {
    GetLookupType();
  }, [])
  // useEffect(() => {
  //   GetListAll();
  // }, []);

  // const handleChangePage = (_event, newPage) => {
  //   setPage(newPage - 1);
  //   GetListAll(newPage, rowsPerPage);
  // };

  // const handleRequestSort = (_event, property) => {
  //   const isAsc = orderBy === property && order === "asc";
  //   setOrder(isAsc ? "desc" : "asc");
  //   setOrderBy(property);
  //   let sort = isAsc ? "desc" : "asc";
  //   let sortExpression = property + " " + sort;
  //   GetListAll(page + 1, rowsPerPage, sortExpression);
  // };

  // const GetProjectDetail = (gid, tableName) => {
  //   showLoading(true);
  //   GidStore.GetDetaiGid(gid, tableName).then(res => {
  //     setProjectSelect(res && res.content ? res.content : null)
  //     showLoading(false)
  //   }).catch(err => console.log(err))
  // }

  // const GetListAll = (pageIndex = 1, pageSize, sort) => {
  //   setPage(pageIndex - 1);
  //   showLoading(true);
  //   apiAction
  //     .GetListAll(pageIndex, pageSize, sort)
  //     .then((res) => {
  //       if (res && res.content) {
  //         console.log(res)
  //         setData(res.content.items);
  //         setTotalItemCount(res.content.totalItemCount);
  //       }
  //       showLoading(false);
  //     })
  //     .catch((err) => {
  //       ShowNotification(
  //         viVN.Errors.AccessDenied,
  //         NotificationMessageType.Error
  //       )
  //       showLoading(false);
  //     });
  // };
  const handleInputChange = (event) => {
    setInput(event.target.value)
  }

  const keyPress = (event) => {
    if (event.keyCode == 13) {
      GetListAll(1, 10, sort, input, place);
      setIsOpenSearch(false)
    }
  }
  const handlePlaceChange = (event) => {
    setPlace(event.target.value)
  }
  const refreshInput = () => {
    setInput('')
    setPlace('')
    setTypeId(0)
    GetListAll(1, 10, sort);
    setIsOpenSearch(false)
  }
  const convertCoordinate = (data) => {
    let arr = data.slice(11, data.indexOf(')')).split(' ')
    let coord = arr.map(i => parseFloat(i))
    let coorObject = { coordinate: coord }
    setCoordinate(coorObject);
    callClickMap(coorObject);
  }

  const callClickMap = (coorObject) => {
    props.controlOpenlayer(ControlFunctionOpenlayerType.ClickProjectItem, coorObject)
  }

  const handleSelectType = (data) => {
    console.log(data);
    setType(data);
    setTypeId(data.id);
    GetListAll(1, 10, sort, input, place, data.id);
  }
  return (
    <div className="container">
      {listProject && (
        <div>
          <div className="row">
            <div className="col-4">
              <div className="length">
                <p>Có <span>{totalItemCount || 0}</span> kết quả</p>
              </div>
            </div>
            <div className="col-4" style={{ position: 'relative' }}>
              <Tooltip title="Tìm kiếm">
                <IconButton
                  type="button"
                  sx={{ p: '10px' }}
                  aria-label="search"
                  onClick={() => setIsOpenSearch(!isOpenSearch)}
                >
                  <SearchIcon />
                </IconButton>
              </Tooltip>
              <ClickOutside show={isOpenSearch} onClickOutside={() => { setIsOpenSearch(false) }}>
                {() => (
                  <div className="left-map-search">
                    <Paper
                      component="form"
                      sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                    >
                      <IconButton sx={{ p: '10px' }} aria-label="menu">
                        <Menu />
                      </IconButton>
                      <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        onChange={handleInputChange}
                        value={input}
                        onKeyDown={keyPress}
                        placeholder="Nhập tên dự án xây dựng"
                        inputProps={{ 'aria-label': 'search google maps' }}
                      />
                      <IconButton
                        type="button"
                        sx={{ p: '10px' }}
                        style={{ float: 'right' }}
                        aria-label="search"
                        onClick={() => refreshInput()}
                      >
                        <Refresh />
                      </IconButton>
                      <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                      <InputBase
                        className="search_item"
                        sx={{ ml: 1, flex: 1 }}
                        style={{ marginLeft: '48px' }}
                        onChange={handlePlaceChange}
                        value={place}
                        onKeyDown={keyPress}
                        placeholder="Nhập địa chỉ dự án"
                        inputProps={{ 'aria-label': 'search google maps' }}
                      />
                      <Select
                        className="search_item"
                        styles={{
                          control: (baseStyles, state) => ({
                            ...baseStyles,
                            borderColor: '#fff',
                            marginLeft: '10px',
                          }),

                        }}
                        options={options}
                        value={type}
                        // Value={
                        //   options.filter((item) => {
                        //     if (typeId === item.value)
                        //       return item
                        //   })
                        // }
                        placeholder='Chọn loại dự án'
                        onChange={handleSelectType}
                      />
                    </Paper>
                  </div>
                )}
              </ClickOutside>
            </div>
            <div className="col-4">
              <div className="sort__">
                <Select
                  options={Options}
                  placeholder='Sắp xếp'
                  onChange={handleSelectSort}
                />
              </div>
            </div>
          </div>
          <div className="list_item">
            {listProject.length > 0 ? (
              <>
                {listProject.map((item, index) => (
                  <div className="project_item">
                    <img
                      className="project_img"
                      src={ApiConfig.api + item.avatar?.filePreview}

                    />
                    <div
                      className="project_content"
                      onClick={() => {
                        setIsOpenInfo(true);
                        convertCoordinate(item.randomPoint);
                        setGid(item.gid);
                        setTableName(item.tableName);
                        setProjectName(item.name)
                      }}
                    >
                      <div className="">
                        <p className="project_title">
                          {item.name}
                        </p>
                        <p className="project_place">
                          Địa chỉ: {item.place}
                        </p>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <div>
                <p>Không có thông tin dự án nào</p>
              </div>
            )}

          </div>
          {totalItemCount && totalItemCount > 0 && (
            <div className="pagination__">
              <Pagination
                showFirstButton
                showLastButton
                page={page + 1}
                count={totalPage}
                siblingCount={1}
                boundaryCount={1}
                color='primary'
                onChange={handleChangePage}
              />
            </div>
          )}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  controlOpenlayer: state.openLayer.handleOutSideFunction,
  isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(ListProject);