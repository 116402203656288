import React from "react";
import "date-fns";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
const SearchLisenceFile = (props) => {
  const {
    owner, address,lisenceFileType,lisenceFileStatus,currentStatus,contructionType,district,
    refresh,
    onSubmit,
    setDistrict,
    setContructionType,
    setCurrentStatus,
    setLisenceFileStatus,
    setLisenceFileType,
    setOwner,
    setAddress
  } = props;
  const refreshFilter = () => {
    setAddress('')
    setOwner('')
    setDistrict([])
    setContructionType('')
    setCurrentStatus(null)
    setLisenceFileType(null)
    setLisenceFileStatus(null)
    refresh();
  };

  const { register, handleSubmit } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const onchangeOwner= (evnt) =>{
    setOwner(evnt.target.value)
  }
  const onchangeAddress= (evnt) =>{
    setAddress(evnt.target.value)
  }
  return (
    <div class="wrap__content-page qlhs-form">
      <form onSubmit={handleSubmit(onSubmit)}>
        <div class="form-row">
          <div class="form-group col-12 col-lg-3">
            <input
              id="input-search"
              type="text"
              value={owner}
              name="owner"
              onChange={onchangeOwner}
              class="form-control"
              placeholder="Chủ sở hữu"
              inputRef={register}
            />
          </div>
          <div class="form-group col-12 col-lg-3">
            <input
              id="input-search"
              type="text"
              value={address}
              name="address"
              onChange={onchangeAddress}
              class="form-control"
              placeholder="Địa chỉ"
              inputRef={register}
            />
          </div>
          <div class="form-group col-12 col-lg-3">
          <Autocomplete
                  options={props.lisenceFileTypeModel}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  value={lisenceFileType}
                  onChange={(event, newValue) => {
                    setLisenceFileType(newValue);
                  }}
                  disableClearable={false}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Loại cấp phép xây dựng"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
          </div>
          <div class="form-group col-12 col-lg-3">
          <Autocomplete
                  options={props.lisenceFileStatusModel}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  value={lisenceFileStatus}
                  onChange={(event, newValue) => {
                    setLisenceFileStatus(newValue);
                  }}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Trạng thái cấp phép xây dựng"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
          </div>
          <div class="form-group col-12 col-lg-3">
          <Autocomplete
                  options={props.lisenceFileCurrentStatusModel}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  value={currentStatus}
                  onChange={(event, newValue) => {
                    setCurrentStatus(newValue);
                  }}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Tình trạng công trình"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
          </div>
          <div class="form-group col-12 col-lg-3">
            <Autocomplete
                  options={props.constructionTypeModel}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  value={contructionType}
                  onChange={(event, newValue) => {
                    setContructionType(newValue);
                  }}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Loại công trình"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
          </div>
          <div class="form-group col-12 col-lg-3">
            <Autocomplete
                  options={props.districtModel}
                  getOptionLabel={(option) =>
                    typeof option === "string" ? option : option.name
                  }
                  value={district}
                  onChange={(event, newValue) => {
                    setDistrict(newValue);
                  }}
                  disableClearable={true}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Huyện/ Thành phố"
                      variant="outlined"
                      size="small"
                    />
                  )}
                />
          </div>
          <div class="form-group col-12 col-lg-3" style={{ display : "flex"}}>
            <button style={{margin:'0px'}} class="btn btn-ct btn-default-ct" onClick={refreshFilter}>
              Xóa
            </button>
            <button class="btn btn-ct btn-primary-ct" type="submit">
              Tìm kiếm
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SearchLisenceFile;
