import React, { useEffect, useState } from "react";
import ClickOutside from "../../../components/click-outside/click-outside";
import { useForm } from "react-hook-form";
import Select from "react-select";
import * as ApiConfig from "../../../api/api-config";
import * as apiAction from "../../../redux/store/lisence-file/lisence-file.store";
import * as districtAction from "../../../redux/store/district-management/district.store";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as viVN from "../../../language/vi-VN.json";
import Slider from "react-slick"
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
    Button,
    TextField,
    Tooltip,
    Divider,
    IconButton,
    InputBase,
    Paper,
} from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import Refresh from "@material-ui/icons/Refresh";
import { Menu } from "@material-ui/icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
    faMapMarkedAlt,
    faHotel,
    faChartArea,
    faStream,
    faUser,
    faBox,
    faCopy,
    faIdCard,
    faEnvelope,
    faPhone,
    faGavel,
    faHouseUser,
    faLevelUpAlt,
    faWarehouse,
    faSchool,
} from "@fortawesome/free-solid-svg-icons";
import "./left-map.scss"
function LeftMap(props) {
    const { data, setData } = props;
    const [lisenceModel, setLisenceModel] = useState([])
    const [lisenceDetail, setLisenceDetail] = useState()
    const [isOpenDetail, setIsOpenDetail] = useState(false)
    const [listInfo, setListInfo] = useState([])
    const [isOpenSearch, setIsOpenSearch] = useState(false)
    const [address, setAddress] = useState('');
    const [addressSearch, setAddressSearch] = useState('');
    const [owner, setOwner] = useState();
    const [district, setDistrict] = useState();
    const [contructionType, setContructionType] = useState();
    const [currentStatus, setCurrentStatus] = useState();
    const [lisenceFileStatus, setLisenceFileStatus] = useState();
    const [lisenceFileType, setLisenceFileType] = useState();
    const [districtModel, setDistrictModel] = useState([]);
    const [lisenceFileTypeModel, setLisenceFileTypeModel] = useState([]);
    const [lisenceFileCurrentStatusModel, setLisenceFileCurrentStatusModel] = useState([]);
    const [lisenceFileStatusModel, setLisenceFileStatusModel] = useState([]);
    const [constructionTypeModel, setConstructionTypeModel] = useState([]);
    const [constructionTierModel, setConstructionTierModel] = useState([]);
    const [totalItemCount, setTotalItemCount] = useState();
    const [page, setPage] = useState(0);

    const settingSlider = {
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 3000,
    };
    const { register, handleSubmit, errors, setValue, clearErrors, setError } =
        useForm({
            mode: "all",
            reValidateMode: "onBlur",
        });
    useEffect(() => {
        if (data) {
            GetDetail(data.gid)
        }
    }, [data])
    useEffect(() => {
        GetListAll();
        GetLookupConstructionType();
        GetLookupLisenceFileCurrentStatus();
        GetLookupLisenceFileStatus();
        GetLookupLisenceFileType();
        GetLookupDistrict();
    }, [])
    const onSubmit = (data) => {
        GetListAll(
            1,
            10,
            null,
            owner,
            district,
            contructionType,
            currentStatus,
            lisenceFileType,
            addressSearch,
            lisenceFileStatus
        )
        setIsOpenSearch(false)
    }
    const GetLookupDistrict = () => {
        districtAction
            .GetLookupDistrict()
            .then((res) => {
                if (res && res.content) {
                    setDistrictModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupLisenceFileType = () => {
        apiAction
            .GetLookupLisenceFileType()
            .then((res) => {
                if (res && res.content) {
                    setLisenceFileTypeModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupLisenceFileStatus = () => {
        apiAction
            .GetLookupLisenceFileStatus()
            .then((res) => {
                if (res && res.content) {
                    setLisenceFileStatusModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupLisenceFileCurrentStatus = () => {
        apiAction
            .GetLookupLisenceFileCurrentStatus()
            .then((res) => {
                if (res && res.content) {
                    setLisenceFileCurrentStatusModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupConstructionTier = () => {
        apiAction
            .GetLookupConstructionTier()
            .then((res) => {
                if (res && res.content) {
                    setConstructionTierModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupConstructionType = () => {
        apiAction
            .GetLookupConstructionType()
            .then((res) => {
                if (res && res.content) {
                    setConstructionTypeModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetListAll = (pageIndex = 1, pageSize, sort, owner, district, contructionType, currentStatus, lisenceFileType, address, lisenceFileStatus) => {
        setPage(pageIndex - 1);
        apiAction
            .GetListAll(
                pageIndex, pageSize, sort, owner, district?.id,
                contructionType?.id, currentStatus?.id, lisenceFileType?.id
                , address, lisenceFileStatus?.id)
            .then((res) => {
                if (res && res.content) {
                    setLisenceModel(res.content.items);
                    setTotalItemCount(res.content.totalItemCount);
                }
            })
            .catch((err) => ShowNotification(
                viVN.Errors.AccessDenied,
                NotificationMessageType.Error
            ));
    };
    const GetDetail = (id) => {
        apiAction.GetDetail(id).then((res) => {
            if (res && res.content) {
                setLisenceDetail(res.content)
                setIsOpenDetail(true)
            }
        })
    }
    const handleViewDetail = (id) => {
        GetDetail(id);
        setIsOpenDetail(true)
    }
    const keyPress = (event) => {
        if (event.keyCode == 13) {
            event.preventDefault();
            GetListAll(
                1,
                10,
                null,
                owner,
                district,
                contructionType,
                currentStatus,
                lisenceFileType,
                addressSearch,
                lisenceFileStatus
            );
        }
    }
    const handleInput = (event) => {
        setAddressSearch(event.target.value)
    }
    const refreshInput = () => {
        setAddressSearch('');
        setOwner('');
        setLisenceFileStatus(null);
        setLisenceFileType(null);
        setCurrentStatus(null);
        setContructionType(null);
        setDistrict(null);
        GetListAll(1,10)
    }
    return (
        <div className="left-map-container">
            <div className="left-map-search">
                <Paper
                    component="form"
                    sx={{ p: '2px 4px', display: 'flex', alignItems: 'center', width: 400 }}
                >
                    <IconButton
                        sx={{ p: '10px' }}
                        style={{ marginRight: '30px' }}
                        aria-label="menu"
                        onClick={() => setIsOpenSearch(!isOpenSearch)}
                    >
                        <Menu />
                    </IconButton>
                    <InputBase
                        sx={{ ml: 1, flex: 1 }}
                        placeholder="Nhập địa chỉ tìm kiếm"
                        inputProps={{ 'aria-label': 'search google maps' }}
                        value={addressSearch}
                        onChange={handleInput}
                        onKeyDown={keyPress}
                    />
                    <IconButton type="button"
                        sx={{ p: '10px' }}
                        style={{ float: 'right' }}
                        aria-label="search"
                        onClick={() => refreshInput()}
                    >
                        <Refresh />
                    </IconButton>
                    <Divider sx={{ height: 28, m: 0.5 }} orientation="vertical" />
                </Paper>
                <ClickOutside show={isOpenSearch} onClickOutside={() => { setIsOpenSearch(false) }}>
                    {() => (
                        <div className="lisence_search_ex">
                            <form onSubmit={handleSubmit(onSubmit)}>
                                <TextField
                                    className="lisence_search_item"
                                    fullWidth
                                    placeholder="Nhập địa chỉ tìm kiếm"
                                    inputRef={register({ maxLength: 300 })}
                                    type="text"
                                    name="adress"
                                    value={addressSearch}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setAddressSearch(e.target.value)}
                                    inputProps={{ maxLength: 300 }}
                                />
                                <TextField
                                    className="lisence_search_item"
                                    fullWidth
                                    placeholder="Chủ thửa"
                                    inputRef={register({ maxLength: 300 })}
                                    type="text"
                                    name="name"
                                    value={owner}
                                    variant="outlined"
                                    size="small"
                                    onChange={(e) => setOwner(e.target.value)}
                                    inputProps={{ maxLength: 300 }}
                                />
                                <Select
                                    className="lisence_search_item"
                                    placeholder="Loại cấp phép xây dựng"
                                    options={lisenceFileTypeModel}
                                    value={lisenceFileType}
                                    onChange={(newValue) => {
                                        setLisenceFileType(newValue);
                                        console.log(newValue)
                                    }}
                                />
                                <Select
                                    className="lisence_search_item"
                                    placeholder="Trạng thái cấp phép xây dựng"
                                    options={lisenceFileStatusModel}
                                    value={lisenceFileStatus}
                                    onChange={(newValue) => {
                                        setLisenceFileStatus(newValue);
                                    }}
                                />
                                <Select
                                    className="lisence_search_item"
                                    placeholder="Tình trạng công trình"
                                    options={lisenceFileCurrentStatusModel}
                                    value={currentStatus}
                                    onChange={(newValue) => {
                                        setCurrentStatus(newValue);
                                    }}
                                />
                                <Select
                                    className="lisence_search_item"
                                    placeholder="Loại công trình"
                                    options={constructionTypeModel}
                                    value={contructionType}
                                    onChange={(newValue) => {
                                        setContructionType(newValue);
                                    }}
                                />
                                <Select
                                    className="lisence_search_item"
                                    placeholder="Huyện/ Thành phố"
                                    options={districtModel}
                                    value={district}
                                    onChange={(newValue) => {
                                        setDistrict(newValue);
                                    }}
                                />
                                <Button
                                    className="lisence_search_btn"
                                    type="submit"
                                    color="primary"
                                    variant="contained"
                                >
                                    <SearchIcon></SearchIcon>
                                    Tìm kiếm
                                </Button>
                            </form>
                        </div>
                    )}
                </ClickOutside>

            </div>
            <div className="row tab_">
                <div
                    onClick={() => setIsOpenDetail(false)}
                    className={`col-6 ${!isOpenDetail ? 'active' : ''}`}
                    style={{ cursor: "pointer" }}
                >
                    <p>Danh sách cấp phép</p>
                </div>
                {lisenceDetail && (
                    <div
                        onClick={() => setIsOpenDetail(true)}
                        className={`col-6 ${isOpenDetail ? 'active' : ''}`}
                        style={{ cursor: "pointer" }}
                    >
                        <p>Thông tin chi tiết</p>
                    </div>
                )}

            </div>
            {isOpenDetail && lisenceDetail && (
                <div className="list_lisence_info p-3">
                    <div className="row">
                        <div className="col-1">
                            <p >
                                <FontAwesomeIcon icon={faMapMarkedAlt} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                            <p style={{fontWeight: "bold"}}>
                               Địa chỉ
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.address}</p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faUser} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Chủ thửa
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.owner}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faIdCard} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Số CMDN/CCCD
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.passport}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faEnvelope} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                               Email
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.email}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faPhone} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Số điện thoại
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.phone}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faWarehouse} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Huyện/TP
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.district}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faChartArea} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Diện tích
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.area} m²</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faBox} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Số thửa
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.parcel}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faCopy} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Số tờ
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.paperNumber}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faHotel} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Loại xây dựng
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.constructionType.name}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faLevelUpAlt} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Cấp xây dựng
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.constructionTier.name}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faChartArea} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Diện tích xây dựng
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.constructionArea} m²</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faHotel} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Tình trạng công trình
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.lisenceFileCurrentStatus.name}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faStream} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Trạng thái cấp phép
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.lisenceFileStatus.name}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faGavel} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Người thẩm định
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.appraiser}</p>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-1">
                            <p>
                                <FontAwesomeIcon icon={faHouseUser} style={{ color: "#005eff", marginRight: '5px' }} />
                            </p>
                        </div>
                        <div className="col-5">
                        <p style={{fontWeight: "bold"}}>
                                Phòng ban
                            </p>
                        </div>
                        <div className="col-6 lisence_info_value">
                            <p>{lisenceDetail.department}</p>
                        </div>
                    </div>
                    <div className="slide">
                        {lisenceDetail.galleries && lisenceDetail.galleries.length > 0 && (
                            <>
                               <p style={{fontWeight: "bold"}}>Ảnh hiện trạng</p>
                                <div>
                                    <Slider {...settingSlider}>
                                        {lisenceDetail.galleries.map(
                                            (item, index) =>
                                                <div key={index}>
                                                    <img src={ApiConfig.api + item.filePreview} alt={item.fileName} />
                                                </div>
                                        )}
                                    </Slider>
                                </div>
                            </>
                        )}
                    </div>
                    {lisenceDetail.documents && lisenceDetail.documents.length > 0 && (
                        <div className="attached_files">
                            <p style={{fontWeight: "bold"}}>Các file văn bản đính kèm</p>
                            {lisenceDetail.documents.map((item) => (
                                <div>
                                    <a href={ApiConfig.api + item.filePreview} download={item.fileName} alt={item.fileName} target="_blank">
                                        {item.fileName.length > 30 ? (item.fileName.substring(0,30)+'...'): item.fileName}
                                    </a>
                                </div>
                            ))}
                        </div>
                    )}
                </div>
            )}
            <div className="left-map-content">
                {!isOpenDetail && lisenceModel && lisenceModel.length > 0 && lisenceModel.map((item) => (
                    <div className="lisence_item">
                        <div className="lisence_item_address">
                            <p>Địa chỉ: {item.address}</p>
                        </div>
                        <div className="">
                            <p>Chủ thửa: {item.owner}</p>
                        </div>
                        <div className="lisence_item_view">
                            <p onClick={() => handleViewDetail(item.id)}>Xem chi tiết &#8594;</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}
export default LeftMap;