import React, { useEffect, useRef } from "react";

function ClickOutside(props) {
    const ref = useRef(null);
    const { onClickOutside } = props;

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ref.current && !ref.current.contains(event.target)) {
                onClickOutside && onClickOutside();
            }
        };
        document.addEventListener('click', handleClickOutside, true);
        return () => {
            document.removeEventListener('click', handleClickOutside, true);
        };
    }, [onClickOutside]);


    return (
        <>
            {props.show ? (
                <div ref={ref} className='info-box'>
                    {props.children()}
                </div>
            ):(<></>)}

        </>
    )
}

export default ClickOutside;
