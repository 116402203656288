import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";

import "./left-menu-project-manage.view.scss"
import { TextField } from "@material-ui/core";

function leftMenuProjectManage(props) {
    const { isLoading, isShow, setIsShow } = props;
    const handleRefresh = () => {
        setIsShow(!isShow);
    }

    // const [haveInfo, setHaveInfo] = useState(false);

    return (
        <div className="left__menu">
            <div className="p-3 mb-4">

                <div className="form-group">
                    <TextField
                        fullWidth
                        id="outlined-basic"
                        label="Nhập tọa độ vn2000"
                        variant="outlined"
                        size="small"
                    />
                </div>
                <button class="btn btn-ct btn-primary-ct btn-inline m-0" type="submit">
                    Tìm kiếm
                </button>
                <button
                    class="btn btn-ct btn-default-ct btn-inline"
                    onClick={handleRefresh}
                >
                    Xóa
                </button>

            </div>
            {isShow && (
                <div className="project__info">
                    <div className="header__info ">
                        <p className="p-3">Thông tin dự án</p>
                    </div>
                    <div className="list__info">
                        <div className="detail__info p-3">
                            <div className="title_name">
                                <p>ProjectName</p>
                            </div>
                            <table>
                                <tr className="">
                                    <th className="">Tên trường</th>
                                    <th className="">Giá trị</th>
                                </tr>
                                <tr>
                                    <td>
                                        diện tích
                                    </td>
                                    <td>
                                        100 m2
                                    </td>
                                </tr>
                            </table>

                        </div>
                        
                    </div>

                </div>
            )}

        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(leftMenuProjectManage);