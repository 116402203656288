import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//--- Action
import * as proviceAction from "../../../redux/store/province-management/province.store";
import {
  DialogActions,
  Button,
  TextField,
  DialogContent,
  DialogTitle,
  Dialog,
  makeStyles,
  Typography,
  IconButton,
  Select as SelectMui,
  MenuItem
} from "@material-ui/core";

import CloseIcon from "@material-ui/icons/Close";
import SaveIcon from "@material-ui/icons/Save";
import FileManagement from "../../../components/file_management/file_management";
import ShowNotification from "../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../utils/configuration";
import * as planningUnitAction from "../../../redux/store/lisence-file/lisence-file.store";
import "suneditor/dist/css/suneditor.min.css";
import * as viVN from "../../../language/vi-VN.json";

import * as appActions from "../../../core/app.store";
import { patternNumber, patternPhone } from "../../../common/validatePhoneNumber";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { stat } from "fs";
const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const EditPlanningUnit = (props) => {
  const {
    isOpen,
    onClose,
    onSuccess,
    planningId,
    GetListAll,
    rowsPerPage,
    districtModel,
    lisenceFileTypeModel,
    lisenceFileCurrentStatusModel,
    lisenceFileStatusModel,
    constructionTypeModel,
    constructionTierModel
  }= props;
  const classes = useStyles();

  const { register, handleSubmit, errors, setValue,clearErrors } = useForm({
    mode: "all",
    reValidateMode: "onBlur",
  });
  const typeModel = [
    { id: 1, name: "Cấp phép xây dựng" }
  ]
  const [documentSelected, setDocumentSelected] = useState([]);
  const [isDocumentShow, setDocumentShow] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [documentsTemp, setDocumentsTemp] = useState([]);

  const [gallerySelected, setGallerySelected] = useState([]);
  const [isGalleryShow, setGalleryShow] = useState(false);
  const [gallery, setGallery] = useState([]);
  const [galleryTemp, setGalleryTemp] = useState([]);

  const [provinceModel, setProvinceModel] = useState([]);
  const [province, setProvince] = useState();
  const [district, setDistrict] = useState();
  const [type, setType] = useState();
  const [status, setStatus] = useState();
  const [constructionType, setConstructionType] = useState();
  const [constructionTier, setConstructionTier] = useState();
  const [lisenceFileCurrentStatus, setLisenceFileCurrentStatus] = useState();
  const GetLookupProvince = () => {
      //showLoading(true);
      proviceAction
          .GetLookupProvince()
          .then((res) => {
              if (res && res.content) {
                  setProvinceModel(res.content);
              }
              //showLoading(false);
          })
          .catch((err) => {
              //showLoading(false);
          });
  }
  const onOpenSelectDocument = () => {
    setDocumentShow(true);
    setDocumentsTemp(documents);
};
const onCloseSelectDocument = () => {
    setDocumentShow(false);
    setDocuments(documentsTemp);
};
const onSaveSelectDocument = () => {
    setDocumentShow(false);
    setDocumentSelected([...documentSelected, ...documents])
};
const onOpenSelectGallery = () => {
    setGalleryShow(true);
    setGalleryTemp(gallery);
};
const onCloseSelectGallery = () => {
    setGalleryShow(false);
    setGallery(galleryTemp);
};
const onSaveSelectGallery = () => {
    setGalleryShow(false);
    setGallerySelected([...gallerySelected, ...gallery])
};
  const [planningUnit, setPlanningUnit] = useState();
  const [planningUnitSelected, setPlanningUnitSelected] = useState(null);
  function handleChangeSelectPlanningUnit(event) {
    setPlanningUnitSelected(event.target.value);
  }
  useEffect(() => {
    GetLookupProvince();
}, [])
  useEffect(() => {
    planningUnitAction
      .GetDetail(planningId)
      .then((res) => {
        if (res && res.content) {
          console.log(res.content);
          res.content.documents && setDocumentSelected(res.content.documents) ;
          res.content.galleries && setGallerySelected(res.content.galleries);
          setPlanningUnit(res.content);
          setProvince(provinceModel.find((item) => item.id === res.content.provinceId))
          setType(lisenceFileTypeModel.find((item) => item.id === res.content.lisenceFileTypeId))
          setStatus(lisenceFileStatusModel.find((item) => item.id === res.content.lisenceFileStatusId))
          setConstructionType(constructionTypeModel.find((item) => item.id === res.content.constructionTypeId))
          setLisenceFileCurrentStatus(lisenceFileCurrentStatusModel.find((item) => item.id === res.content.lisenceFileCurrentStatusId))
          setConstructionTier(constructionTierModel.find((item) => item.id === res.content.constructionTierId) )
          setDistrict(districtModel.find((item) => item.id === res.content.districtId))

        }
      })
      .catch((err) => console.log(err));
  }, [planningId]);

  const onSubmit = (data) => {
    if (!data) {
      return;
    }
    let formData = new FormData();
    formData.append("id", planningId);
    data.parcel && formData.append("parcel", data.parcel);
    data.paperNumber && formData.append("paperNumber", data.paperNumber);
    data.passport && formData.append("passport", data.passport);
    data.owner && formData.append("owner", data.owner);
    data.email && formData.append("email", data.email);
    data.phone && formData.append("phone", data.phone);
    data.address && formData.append("address", data.address);
    data.department && formData.append("department", data.department);
    data.appraiser && formData.append("appraiser", data.appraiser);
    data.area && formData.append("area", data.area);
    data.certificateNumber && formData.append("certificateNumber", data.certificateNumber);
    data.constructionArea && formData.append("constructionArea", data.constructionArea);
    data.floorArea && formData.append("floorArea", data.floorArea);
    data.note && formData.append("note", data.note);
    province && formData.append("provinceId", province.id);
    district && formData.append("districtId", district.id);

    type && formData.append("lisenceFileTypeId", type.id);
    status && formData.append("lisenceFileStatusId", status.id);
    constructionType && formData.append("constructionTypeId", constructionType.id);
    lisenceFileCurrentStatus && formData.append("lisenceFileCurrentStatusId", lisenceFileCurrentStatus.id);
    constructionTier && formData.append("constructionTierId", constructionTier.id);
    // data.phone && formData.append("phone", data.phone);
    // data.phone && formData.append("phone", data.phone);
    // data.phone && formData.append("phone", data.phone);
    documentSelected.length > 0 && documentSelected.map(item => {
      formData.append('documentIds', item.fileId)
    })
    gallerySelected.length > 0 && gallerySelected.map(item => {
      formData.append('galleryIds', item.fileId)
    })

    planningUnitAction
      .UpdateLisenceFile(formData)
      .then((result) => {
        if (result) {
          onSuccess();
          ShowNotification(
            "Cập nhật thông tin cấp phép thành công!",
            NotificationMessageType.Success
          );
          GetListAll(1, rowsPerPage);
        }
      })
      .catch((err) => {
        onSuccess();
        ShowNotification(
          err.errorMessage,
          NotificationMessageType.Error
        );
      });
  };

  return (
    <div>
      <Dialog open={isOpen} onClose={onClose} fullWidth={true} maxWidth="md">
        <DialogTitle disableTypography className="border-bottom">
          <Typography variant="h6">Chỉnh sửa cấp phép xây dựng</Typography>
          <IconButton
            aria-label="close"
            className={classes.closeButton}
            onClick={onClose}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          {planningUnit && (
            <DialogContent className="pt-4 pb-2">
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Số thửa<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      
                      maxLength: 300,
                    })}
                    type="text"
                    name="parcel"
                    error={
                      (errors.parcel &&
                        errors.parcel.type === "required") ||
                      (errors.parcel &&
                        errors.parcel.type === "maxLength")
                    }
                    defaultValue={planningUnit?.parcel}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.parcel &&
                    errors.parcel.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.parcel &&
                    errors.parcel.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Số tờ<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      required: true,
                      maxLength: 300,
                    })}
                    type="text"
                    name="paperNumber"
                    defaultValue={planningUnit?.paperNumber}
                    error={
                      (errors.paperNumber &&
                        errors.paperNumber.type === "required") ||
                      (errors.paperNumber &&
                        errors.paperNumber.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.paperNumber &&
                    errors.paperNumber.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.paperNumber &&
                    errors.paperNumber.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Chủ sở hữu<span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      required: true,
                      maxLength: 300,
                    })}
                    defaultValue={planningUnit?.owner}
                    type="text"
                    name="owner"
                    error={
                      (errors.owner &&
                        errors.owner.type === "required") ||
                      (errors.owner &&
                        errors.owner.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.owner &&
                    errors.owner.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.owner &&
                    errors.owner.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Số CCCD/CMND
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      maxLength: 300,
                    })}
                    type="text"
                    name="passport"
                    defaultValue={planningUnit?.passport}
                    error={
                      (errors.passport &&
                        errors.passport.type === "required") ||
                      (errors.passport &&
                        errors.passport.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.passport &&
                    errors.passport.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.passport &&
                    errors.passport.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Email
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      maxLength: 300,
                    })}
                    type="email"
                    name="email"
                    defaultValue={planningUnit?.email}
                    error={
                      (errors.email &&
                        errors.email.type === "required") ||
                      (errors.email &&
                        errors.email.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.email &&
                    errors.email.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.email &&
                    errors.email.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Số điện thoại
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      maxLength: 300,
                    })}
                    type="number"
                    name="phone"
                    defaultValue={planningUnit?.phone}
                    error={
                      (errors.phone &&
                        errors.phone.type === "required") ||
                      (errors.phone &&
                        errors.phone.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.phone &&
                    errors.phone.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.phone &&
                    errors.phone.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Tỉnh
                    <span className="required"></span>
                  </label>
                  <Autocomplete
                    options={provinceModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    value={{
                      id: planningUnit.provinceId,
                      name: "Thanh Hóa",
                  }}
                    defaultValue={province}
                    onChange={(event, newValue) => {
                      setProvince(newValue);
                      setValue("provinceId", newValue?.id)
                      clearErrors("provinceId");
                    }}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="provinceId"
                        {...register('provinceId')}
                        error={
                          errors.provinceId &&
                          errors.provinceId.type === "required"
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  {errors.provinceId &&
                    errors.provinceId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Huyện/ Thành Phố
                    <span className="required"></span>
                  </label>
                  <Autocomplete
                    options={districtModel}
                    defaultValue={ districtModel.find((item) => item.id === planningUnit?.districtId)}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    value={district}
                    onChange={(event, newValue) => {
                      setDistrict(newValue);
                      setValue("districtId", newValue?.id)
                      clearErrors("districtId");
                    }}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="districtId"
                        {...register('districtId')}
                        error={
                          errors.districtId &&
                          errors.districtId.type === "required"
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  {errors.districtId &&
                    errors.districtId.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Loại cấp phép
                    <span className="required"></span>
                  </label>
                  <Autocomplete
                    options={lisenceFileTypeModel}
                    defaultValue={planningUnit?.lisenceFileType}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    value={type}
                    onChange={(event, newValue) => {
                      setType(newValue);
                      setValue("type", newValue?.id)
                      clearErrors("type");
                    }}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="type"
                        {...register('type')}
                        error={
                          errors.type &&
                          errors.type.type === "required"
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  {errors.type &&
                    errors.type.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Trạng thái
                    <span className="required"></span>
                  </label>
                  <Autocomplete
                    options={lisenceFileStatusModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    value={status}
                    defaultValue={lisenceFileStatusModel.find((item) => item.id === planningUnit?.lisenceFileStatusId)}
                    onChange={(event, newValue) => {
                      setStatus(newValue);
                      setValue("status", newValue?.id)
                      clearErrors("status");
                    }}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="status"
                        {...register('status')}
                        error={
                          errors.status &&
                          errors.status.type === "required"
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  {errors.status &&
                    errors.status.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Loại công trình xây dựng 
                    <span className="required"></span>
                  </label>
                  <Autocomplete
                    options={constructionTypeModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    defaultValue={planningUnit?.constructionType}
                    value={constructionType}
                    onChange={(event, newValue) => {
                      setConstructionType(newValue);
                      setValue("contructionType", newValue?.id)
                      clearErrors("contructionType");
                    }}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="contructionType"
                        {...register('contructionType')}
                        error={
                          errors.contructionType &&
                          errors.contructionType.type === "required"
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  {errors.contructionType &&
                    errors.contructionType.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Cấp 
                    <span className="required"></span>
                  </label>
                  <Autocomplete
                    options={constructionTierModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    value={constructionTier}
                    defaultValue={planningUnit?.constructionTier}
                    onChange={(event, newValue) => {
                      setConstructionTier(newValue);
                      setValue("constructionTier", newValue?.id)
                      clearErrors("constructionTier");
                    }}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="constructionTier"
                        {...register('constructionTier')}
                        error={
                          errors.constructionTier &&
                          errors.constructionTier.type === "required"
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  {errors.constructionTier &&
                    errors.constructionTier.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Địa chỉ <span className="required"></span>
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({                     
                      maxLength: 300,
                    })}
                    type="text"
                    name="address"
                    error={
                      (errors.address &&
                        errors.address.type === "required") ||
                      (errors.address &&
                        errors.address.type === "maxLength")
                    }
                    defaultValue={planningUnit?.address}
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.address &&
                    errors.address.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                  {errors.address &&
                    errors.address.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Tình trạng công trình
                    <span className="required"></span>
                  </label>
                  <Autocomplete
                    options={lisenceFileCurrentStatusModel}
                    getOptionLabel={(option) =>
                      typeof option === "string" ? option : option.name
                    }
                    value={lisenceFileCurrentStatus}
                    defaultValue={lisenceFileCurrentStatusModel.find((item) => item.id === planningUnit?.lisenceFileCurrentStatusId)}
                    onChange={(event, newValue) => {
                      setLisenceFileCurrentStatus(newValue);
                      setValue("lisenceFileCurrentStatus", newValue?.id)
                      clearErrors("lisenceFileCurrentStatus");
                    }}
                    disableClearable={true}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        name="lisenceFileCurrentStatus"
                        {...register('lisenceFileCurrentStatus')}
                        error={
                          errors.lisenceFileCurrentStatus &&
                          errors.lisenceFileCurrentStatus.type === "required"
                        }
                        variant="outlined"
                        size="small"
                      />
                    )}
                  />
                  {errors.lisenceFileCurrentStatus &&
                    errors.lisenceFileCurrentStatus.type === "required" && (
                      <span className="error">Trường này là bắt buộc</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Phòng ban phụ trách
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      
                      maxLength: 300,
                    })}
                    defaultValue={planningUnit?.department}
                    type="string"
                    name="department"
                    error={
                      (errors.department &&
                        errors.department.type === "required") ||
                      (errors.department &&
                        errors.department.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.department &&
                    errors.department.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Thẩm định
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                    
                      maxLength: 300,
                    })}
                    type="text"
                    defaultValue={planningUnit?.appraiser}
                    name="appraiser"
                    error={
                      (errors.appraiser &&
                        errors.appraiser.type === "required") ||
                      (errors.appraiser &&
                        errors.appraiser.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.appraiser &&
                    errors.appraiser.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Diện tích
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      maxLength: 300,
                    })}
                    type="number"
                    name="area"
                    defaultValue={planningUnit?.area}
                    error={
                      (errors.area &&
                        errors.area.type === "required") ||
                      (errors.area &&
                        errors.area.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.area &&
                    errors.area.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Số chứng nhận
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      maxLength: 300,
                    })}
                    type="text"
                    defaultValue={planningUnit?.certificateNumber}
                    name="certificateNumber"
                    error={
                      (errors.certificateNumber &&
                        errors.certificateNumber.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.certificateNumber &&
                    errors.certificateNumber.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <label className="text-dark">
                    Diện tích xây dựng
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      maxLength: 300,
                    })}
                    type="number"
                    name="constructionArea"
                    defaultValue={planningUnit?.constructionArea}
                    error={
                      (errors.constructionArea &&
                        errors.constructionArea.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.constructionArea &&
                    errors.constructionArea.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
                <div className="col-6">
                  <label className="text-dark">
                    Diện tích sàn
                  </label>

                  <TextField
                    fullWidth
                    inputRef={register({
                      
                      maxLength: 300,
                    })}
                    type="number"
                    defaultValue={planningUnit?.floorArea}
                    name="floorArea"
                    error={
                      (errors.floorArea &&
                        errors.floorArea.type === "maxLength")
                    }
                    variant="outlined"
                    size="small"
                    inputProps={{ maxLength: 300 }}
                  />
                  {errors.floorArea &&
                    errors.floorArea.type === "maxLength" && (
                      <span className="error">Tối đa 300 ký tự</span>
                    )}
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label className="text-dark">Ghi chú</label>
                  <textarea
                    name="note"
                    defaultValue={planningUnit?.note}
                    rows="5"
                    className="form-control"
                    ref={register}
                  ></textarea>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label className="text-dark">File đính kèm</label> </div>
                <div className="col-12">
                  <div className="list__img d-flex flex-wrap mt-1">
                    {!isDocumentShow &&
                      documentSelected &&
                      documentSelected.length > 0 &&
                      documentSelected.map((item) => (
                        <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                          <img
                            src={require("../../../assets/icon/default.svg")}
                            alt={item.fileName}
                            title={item.fileName}
                            className="img-fluid mb-2"
                            style={{
                              width: "auto",
                              height: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                          <p className="file_name">{item.fileName.length > 15 ? (item.fileName.substring(0,15)+'...') : item.fileName}</p>
                          <p
                            className="close_x"
                            onClick={() =>
                              setDocumentSelected(documentSelected.filter(i => i !== item))
                            }
                          >
                            &#10005;
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onOpenSelectDocument}
                    >
                      Chọn file
                    </Button>
                  </div>
                </div>
              </div>
              <div className="form-group row">
                <div className="col-12">
                  <label className="text-dark">Thư viện ảnh</label> </div>
                <div className="col-12">
                  <div className="list__img d-flex flex-wrap mt-1">
                    {!isGalleryShow &&
                      gallerySelected &&
                      gallerySelected.length > 0 &&
                      gallerySelected.map((item) => (
                        <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                          <img
                            src={require("../../../assets/icon/default.svg")}
                            alt={item.fileName}
                            title={item.fileName}
                            className="img-fluid mb-2"
                            style={{
                              width: "auto",
                              height: "auto",
                              maxWidth: "100%",
                              maxHeight: "100%",
                            }}
                          />
                          <p className="file_name">{item.fileName.length > 15 ? (item.fileName.substring(0,15)+'...') : item.fileName}</p>
                          <p
                            className="close_x"
                            onClick={() =>
                              setGallerySelected(gallerySelected.filter(i => i !== item))
                            }
                          >
                            &#10005;
                          </p>
                        </div>
                      ))}
                  </div>
                </div>
                <div className="col-12">
                  <div>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={onOpenSelectGallery}
                    >
                      Chọn file
                    </Button>
                  </div>
                </div>
              </div>
            </DialogContent>
            
          )}
            {isDocumentShow && (
                <Dialog
                    onClose={onCloseSelectDocument}
                    open={isDocumentShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectDocument}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={documents}
                            fileSelected={documentSelected}
                            setFiles={setDocuments}
                            acceptedFiles={["doc", "docx", "txt", "pdf", "png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectDocument}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {documents && documents.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectDocument}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
            {isGalleryShow && (
                <Dialog
                    onClose={onCloseSelectGallery}
                    open={isGalleryShow}
                    fullWidth={true}
                    maxWidth="md"
                    className="dialog-preview-form"
                >
                    <DialogTitle disableTypography>
                        <Typography variant="h6">Quản lý file</Typography>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={onCloseSelectGallery}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <DialogContent dividers>
                        <FileManagement
                            multiple={true}
                            files={gallery}
                            // fileSelected={gallerySelected}
                            setFiles={setGallery}
                            acceptedFiles={["png", "jpg"]}
                        />
                    </DialogContent>

                    <DialogActions>
                        <Button
                            type="button"
                            onClick={onCloseSelectGallery}
                            variant="contained"
                            startIcon={<CloseIcon />}
                        >
                            Hủy
                        </Button>
                        {gallery && gallery.length > 0 && (
                            <Button
                                type="button"
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                                onClick={onSaveSelectGallery}
                            >
                                Lưu
                            </Button>
                        )}
                    </DialogActions>
                </Dialog>
            )}
          <DialogActions className="border-top">
            <Button
              type="button"
              onClick={onClose}
              variant="contained"
              startIcon={<CloseIcon />}
            >
              Hủy
            </Button>
            <Button
              type="submit"
              color="primary"
              variant="contained"
              startIcon={<SaveIcon />}
            >
              Lưu
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </div>
  );
};
const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      showLoading: appActions.ShowLoading,
    },
    dispatch
  );

export default connect(null, mapDispatchToProps)(EditPlanningUnit);
