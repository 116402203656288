import React, { useEffect, useState } from "react";
import * as InitMapStore from "../../redux/store/init-map/init-map.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import OpenlayerMapView from "../../components/open-layer/open-layer";
import functionButton from "../init-map-data/function-button/function-button";
import * as mapAction from "../../redux/store/project-management/gid.store"
import * as appActions from "../../core/app.store";
import CreatMapView from "./create-map/create-map";
import InitMapDataView from "../init-map-data/init-map-data";
import "./project-management.scss"
//import ListProject from "./list-project/list-project.view"
const MapProjectManagement = (props) => {
    const { showLoading } = props;
    const planningId = 150;
    const mapId = 148;

    const [extend, setExtend] = useState([]);
    const [zoom, setZoom] = useState();
    const [mapLayer, setMapLayer] = useState();
    const [dataItems, setDataItem] = useState();
    const [isFist, setIsFist] = useState(false);
    const [baseMaps, setBaseMaps] = useState();
    const [layers, setLayers] = useState();
    const [mapSetting, setMapSetting] = useState();
    const [isOpenMap, setIsOpenMap] = useState(false);
    const [projectModel, setProjectModel] = useState([]);

    const handleCloseCreatMapModal = () => {
        return;
    };
    return (
        <>
            <div className="">
                <div className="row map_project_container">
                    <div className="col-12">
                        <InitMapDataView
                            mapId={mapId}
                            planningId={planningId}
                            isProject={false}
                        />
                    </div>
                </div>
            </div>

            {/* <div className="h-100" style={{ marginTop: "60px" }}>
                <CreatMapView
                    isProject={false}
                    open={true}
                    mapId={mapId}
                    planningId={planningId}
                    handleClose={() => handleCloseCreatMapModal()}
                    planningName={"test"}
                />
            </div> */}
        </>
    )
}
const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
    mapSetting: state.initMap.mapSetting,
    settings: state.clientSetting.clientSetting,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(MapProjectManagement);
