import Service from "../../../api/api-service";
import { ApiUrl } from "../../../api/api-url";
import * as config from "../../../common/config"
import NotificationService from "../../../common/notification-service";

const service = new Service();

export const GetDetaiGid = (gid, tableName) => {
    const params = new URLSearchParams();
    params.append("gid", gid);
    params.append("tableName", tableName);
    return service
      .get(ApiUrl.GetDetaiGid, params)
      .then((res) => {
        return res;
      })
      .catch(err => {
        NotificationService.error(err.errorMessage);
        return
      })
}