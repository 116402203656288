import React, { useEffect, useState } from "react";
import "./init-map-data.scss";
import OpenlayerMapView from "../../components/open-layer/open-layer";
import LeftControlView from "./left-control/left-control";
import RenderSettingGeneralPopup from "./setting-general-popup/setting-general-popup.view.jsx";
import * as InitMapStore from "../../redux/store/init-map/init-map.store";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import BottomControl from "./bottom-control/botttom-control";
import FunctionButton from "./function-button/function-button";

function InitMapDataView(props) {
  const [extend, setExtend] = useState([]);
  const [zoom, setZoom] = useState();
  const [mapLayer, setMapLayer] = useState();
  const [dataItems, setDataItem] = useState();
  const [isFist, setIsFist] = useState(false);

  useEffect(() => {
    console.log("voos", mapLayer);
    if (props.planningId) {
      if (props.mapId) props.GetMapDetailById(props.mapId, props.planningId);
      else props.UpdateDefaultMapData(props.planningId);
    } else props.GetMapDetailById(21, 1);
    props.GetAllBaseMapDefault();
    setMapLayer(undefined);
    setDataItem(undefined);
  }, []);

  useEffect(() => {
    if (isFist) {
      setDataItem(undefined);
    }
    setIsFist(true);
  }, [props.layers]);

  // useEffect(() => {
  //     if (props.planningId) {
  //         if (props.mapId) props.UpdateDefaultMapData(props.planningId, props.mapId); else props.UpdateDefaultMapData(props.planningId)
  //     } else props.UpdateDefaultMapData(21, 1);
  //     props.GetAllBaseMapDefault();
  // }, []);

  const handleGetDataClick = (value) => {
    console.log("value", value);
    setDataItem(undefined);
    setDataItem(value);
  };

  return (
    <div
      className="init-map-data-container h-100 container-fluid p-0"
      style={{ overflowX: "hidden" }}
    >
      <div className="row h-100 p-0 m-0">
        <div className="col-3 h-100 p-0">
          <LeftControlView
            planningId={props.planningId}
            mapId={props.mapId}
            isLock={props.isLock}
            dataItems={dataItems} />
        </div>
        <div className="col-9 h-100 p-0">
          {props.initMap.hasLoadingData && (
            <OpenlayerMapView
              setExtend={setExtend}
              setZoom={setZoom}
              setMapLayer={setMapLayer}
            />
          )}
        </div>
        {props.initMap.isOpenSettingGeneralModal && (
          <RenderSettingGeneralPopup extend={extend} zoom={zoom} />
        )}
      </div>
      <div className="relative">
        {dataItems && <BottomControl isLock={props.isLock} dataItems={dataItems} />}
      </div>
      <div>
        {mapLayer && (
          <FunctionButton
            key={Date.now.toString()}
            mapLayer={mapLayer}
            planningName={props.planningName}
            handleGetDataClick={handleGetDataClick}
          />
        )}
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  initMap: state.initMap,
  layers: state.initMap.layers,
});

const mapDispatchToProps = (dispatch) =>
  bindActionCreators(
    {
      GetMapDetailById: InitMapStore.GetMapDetailById,
      GetAllBaseMapDefault: InitMapStore.GetAllBaseMap,
      UpdateDefaultMapData: InitMapStore.UpdateDefaultMapData,
    },
    dispatch
  );

export default connect(mapStateToProps, mapDispatchToProps)(InitMapDataView);
