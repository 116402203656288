import React, { useEffect, useState } from "react";
import * as appActions from "../../../../core/app.store";
import "../layers/layers.scss";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { Tooltip, IconButton, makeStyles, Typography } from "@material-ui/core";
import NotificationService from "../../../../common/notification-service";

//--- Material Icon
import PlaylistAddIcon from "@material-ui/icons/PlaylistAdd";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import CloseIcon from "@material-ui/icons/Close";
//ConstructionInvestmentForm
import ConstructionInvestmentForm from "../ConstructionInvestmentForm/ConstructionInvestmentForm.view";
import ConstructionInvestmentFormEdit from "../EditConstructionInvestment/edit-constructionInvestment.view"
import ConstructionInvestmentDetail from "../constructionInvestmentDetail/constructionInvestmentDetail.view"
import * as ConstructionAction from "../../../../redux/store/project-management/constructionInvestment.store";
import ListProject from "../../../project-management/list-project/list-project.view"
import ShowNotification from "../../../../components/react-notifications/react-notifications";
import { NotificationMessageType } from "../../../../utils/configuration";
import * as viVN from "../../../../language/vi-VN.json";

function ConstructionInvestment(props) {
    const { showLoading, dataItems, GetListProject, setCoordinate, gid, setGid, tableName, setTableName } = props;
    const [listProject, setListProject] = useState();
    const [projectId, setProjectId] = useState();
    const [projectName, setProjectName] = useState('');
    const [isOpenCIForm, setIsOpenCIForm] = useState(false);
    const [isOpenCIDetail, setIsOpenCIDetail] = useState(false);
    const [isOpenCIEdit, setIsOpenCIEdit] = useState(false);
    const [isOpenCIDelete, setIsOpenCIDelete] = useState(false);
    const [page, setPage] = useState(1);
    const [totalPage, setTotalPage] = useState();
    const [totalItemCount, setTotalItemCount] = useState();
    const [projectModel, setProjectModel] = useState([]);
    const [sort, setSort] = useState('modifiedDate desc');
    const [input, setInput] = useState("");

    const pageSize = 10;
    useEffect(() => {
        GetListAll();
    }, [])

    const handleChangePage = (_event, newPage) => {
        setPage(newPage - 1);
        GetListAll(newPage,pageSize,sort,input);
      };

    const handleSelectSort = (e) => {
        GetListAll(1, 10, e.value,input);
        setSort(e.value)
    }

    const GetListAll = (pageIndex = 1, pageSize, sort, input, place, typeId) => {
        setPage(pageIndex - 1);
        showLoading(true);
        ConstructionAction
            .GetListAll(pageIndex, pageSize, sort, input, place, typeId)
            .then((res) => {
                if (res && res.content) {
                    console.log(res)
                    setListProject(res.content.items);
                    setTotalItemCount(res.content.totalItemCount);
                    setTotalPage(res.content.pageCount)
                }
                showLoading(false);
            })
            .catch((err) => {
                ShowNotification(
                    viVN.Errors.AccessDenied,
                    NotificationMessageType.Error
                )
                showLoading(false);
            });
    };

    useEffect(() => {
        if (dataItems)
            setIsOpenCIDetail(true);
    }, [dataItems])

    return (
        <>
            <ConstructionInvestmentForm
                isOpen={isOpenCIForm}
                dataItems={dataItems}
                setIsOpen={setIsOpenCIForm}
                GetListProject={GetListProject}
                setIsOpenCIDetail={setIsOpenCIDetail}
                GetListAll={GetListAll}
            />
            {/* <ConstructionInvestmentFormEdit
                isOpen={isOpenCIEdit}
                dataItems={dataItems}
                setIsOpen={setIsOpenCIEdit}
                GetListProject={GetListProject}
                projectModel={projectModel}
            /> */}

            <div className="construction">
                <div className="mb-4">

                    
                    <div className="row tab_">
                        <div
                            onClick={() => setIsOpenCIDetail(false)}
                            className={`col-6 ${!isOpenCIDetail ? 'active' : ''}`}
                            style={{ cursor: "pointer" }}
                        >
                            <p>Danh sách dự án</p>
                        </div>
                        {gid && tableName && (
                            <div
                                onClick={() => setIsOpenCIDetail(true)}
                                className={`col-6 ${isOpenCIDetail ? 'active' : ''}`}
                                style={{ cursor: "pointer" }}
                            >
                                <p>Thông tin chi tiết</p>
                            </div>
                        )}

                    </div>
                    {isOpenCIDetail ? (
                        <ConstructionInvestmentDetail
                            dataItems={dataItems}
                            gid={gid}
                            tableName={tableName}
                            setIsOpenCIEdit={setIsOpenCIEdit}
                            setIsOpenCIForm={setIsOpenCIForm}
                            refreshListProject={GetListProject}
                            setIsOpenCIDetail={setIsOpenCIDetail}
                            GetListAll={GetListAll}
                        />
                    ) : (
                        <ListProject
                            setCoordinate={setCoordinate}
                            setGid={setGid}
                            setTableName={setTableName}
                            setIsOpenInfo={setIsOpenCIDetail}
                            setProjectName={setProjectName}
                            setListProject={setListProject}
                            listProject={listProject}
                            totalItemCount={totalItemCount}
                            page={page}
                            setPage={setPage}
                            totalPage={totalPage}
                            handleChangePage={handleChangePage}
                            handleSelectSort={handleSelectSort}
                            GetListAll={GetListAll}
                            input={input}
                            setInput={setInput}
                            sort={sort}
                        />
                    )}
                    

                </div>
            </div>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(ConstructionInvestment);