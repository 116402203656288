import React from "react";
import CreatMapView from "../create-map/create-map";
import InitMapDataView from "../init-map-view/init-map";
import '../lisence-file.scss'

function MapLisenceFile(props) {
    const planningId = 160;
    const mapId = 154;
    return (
        <>
            <div className="row map_lisence_container">
                <div className="col-12">
                    <InitMapDataView mapId={mapId} planningId={planningId} />
                </div>
            </div>
        </>
    )
}

export default MapLisenceFile