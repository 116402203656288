import logoTTQH from "../assets/icon/logoTTQH.png";
import logoPAHT from "../assets/icon/logoPAHT.png";
import logoPTQD from "../assets/icon/logoPTQĐ.png";
import logoAdmin from "../assets/icon/logoAdmin.png";
import { UrlCollection } from "./url-collection";

export const modules = [
  {
    title: "Quản lý quy hoạch xây dựng",
    url: UrlCollection.QHDT,
    logo: logoTTQH,
    isHomeView: true,
  },
  {
    title: "Quản lý cấp phép xây dựng",
    url: UrlCollection.CPXD,
    logo: logoPAHT,
    isHomeView: true,
  },
  {
    title: "Quản lý hạ tâng kỹ thuật",
    url: UrlCollection.QH_HTKT,
    logo: logoTTQH,
    isHomeView: true,
  },
  {
    title: "Quản lý dự án đầu tư xây dựng trên địa bàn",
    url: UrlCollection.ProjectManagement,
    logo: logoPTQD,
    isHomeView: true,
  },
  {
    title: "Quản trị hệ thống",
    url: UrlCollection.EmailTemplate,
    logo: logoAdmin,
    isHomeView: true,
  },
];