import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import InitMapDataView from "../init-map-view/init-map-view";
import * as planningUnitAction from "../../../redux/store/lisence-file/lisence-file.store";
import LisenceFileForm from "./lisence-form.view"
import NotificationService from "../../../common/notification-service";
// muiUi
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
} from "@material-ui/core";
//import AddIcon from "@material-ui/icons/AddIcon";
import SearchIcon from "@material-ui/icons/Search";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./add-lisence-file.scss"

function AddLisenceFile(props) {
    const { showLoading } = props;
    const planningId = 160;
    const mapId = 154;
    const defaultPolygon = [
        [],
        [],
        [],
    ]
    const [polygon, setPolygon] = useState(defaultPolygon);
    const [polygonSelect, setPolygonSelect] = useState([]);
    const [point, setPoint] = useState();
    const [planningInfo, setPlanningInfo] = useState();
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [isLisence, setIsLisence] = useState(false);
    const [polygonCoords, setPolygonCoords] = useState();
    const [polygonVN2000, setPolygonVN2000] = useState([]);
    const [isVN2000, setIsVN2000] = useState(true);
    const [reload, setReload] = useState(true);
    const { register, handleSubmit, errors, setValue, clearErrors, setError } =
        useForm({
            mode: "all",
            reValidateMode: "onBlur",
        });
    const refreshValue = () => {
        setPolygon(defaultPolygon);
        setPolygonSelect([])
        setPoint(null)
        setPlanningInfo(null)
    }
    const getPlanningByPolygon = (polygon) => {
        showLoading(true)
        planningUnitAction.SearchPlanningByPolygon(polygon).then((res) => {
            if (res && res.content.result) {
                setPlanningInfo(res.content.result);
            }
            setIsLisence(true)
            showLoading(false);
        }).catch(err => showLoading(false))
    }

    const addPoint = () => {
        setPolygon([...polygon, []]);
    }
    const subPoint = (index) => {
        setPolygon(polygon.filter(item => item !== polygon[index]));
    }
    const convertPolygon = (polygon_) => {
        let result = ''
        let data = polygon_.map(i => {
            let str = i.toString();
            return str.replace(",", " ")
        })
        result = `POLYGON((${data.slice(' ')}))`;
        return result;
    }
    const searchPolygon = () => {
        if (polygon.length > 2) {
            if (isVN2000) {
                let result = [];
                polygon.map((item) => {
                    showLoading(true)
                    planningUnitAction.VN2000toWgs84(item[1], item[0]).then((res) => {
                        if (res) {
                            console.log(res)
                            result.push(res)
                        }
                        showLoading(false)
                    }).catch(err => console.log(err))
                })
                showLoading(false)
                setTimeout(() => {
                    showLoading(true)
                    setPolygonVN2000(result)
                    const firstElement = result.slice(0, 1);
                    setPolygonCoords(result.concat(firstElement));
                    getPlanningByPolygon(convertPolygon(result.concat(firstElement)))
                }, 500)

            } else {
                const firstElement = polygon.slice(0, 1);
                setPolygonCoords(polygon.concat(firstElement));
                console.log(polygon.concat(firstElement))
                getPlanningByPolygon(convertPolygon(polygon.concat(firstElement)))
            }
        }
        else {
            NotificationService.warning("Bạn cần nhập tối thiểu 3 điểm");
        }
    }
    const handleInput = (evt, index, index_) => {
        let data = [...polygon]
        console.log(evt.target.value)
        if (index_) {
            data[index][0] = parseFloat(evt.target.value && evt.target.value)
        } else {
            data[index][1] = parseFloat(evt.target.value)
        }
        setPolygon(data)
    }
    useEffect(() => {
        if (polygon && polygon.length < 3) {
            setIsLisence(false);
        }
    }, [polygon])
    useEffect(() => {
        if (point) {
            setPolygonSelect([...polygonSelect, point])
        }
    }, [point])

    useEffect(() => {
        if (polygonSelect !== []) {
            let data = polygonSelect.filter((item, index) => {
                return polygonSelect.indexOf(item) === index
            })
            setPolygon(data = [] ? defaultPolygon : data)
        }
    }, [polygonSelect])
    return (
        <>
            <div className="row add-lisence-file">
                <div className="col-4 add-lisence-file-form">
                    <div className="row">
                        <div className="col-12">
                            <div className="row mb-3 mt-3"
                                style={{ marginLeft: "3px" }}
                            >
                                <span className="col-12"
                                    style={{ fontWeight: "bold", padding: "10px 0" }}
                                > Nhập tọa độ thừa đất</span>
                                <div className="col-6">
                                    <Button
                                        color="primary"
                                        variant={isVN2000? 'contained': 'outlined'}
                                        onClick={() => setIsVN2000(true)}
                                    >
                                        Tọa độ VN2000
                                    </Button>
                                </div>
                                <div className="col-6">
                                    <Button
                                        color="primary"
                                        variant={!isVN2000? 'contained': 'outlined'}
                                        onClick={() => setIsVN2000(false)}
                                    >
                                        Tọa độ WGS84
                                    </Button>
                                </div>
                            </div>

                            <form onSubmit={handleSubmit(searchPolygon)}>
                                {polygon.map((item, index) => (
                                    <div key={index} className="row mb-3">
                                        <div className="col-5">
                                            <TextField
                                                type="number"
                                                step="any"
                                                name={'lng' + index}
                                                label={isVN2000 ? 'Nhập X' : 'Nhập tọa độ lng'}
                                                id="outlined-basic"
                                                variant="outlined"
                                                size="small"
                                                //value={polygon[index][0]}
                                                onChange={evt => handleInput(evt, index, true)}
                                                inputRef={register({ required: true })}
                                            />
                                        </div>
                                        <div className="col-5">
                                            <TextField
                                                type="number"
                                                step="any"
                                                name={'lat' + index}
                                                label={isVN2000 ? 'Nhập Y' : 'Nhập tọa độ lat'}
                                                id="outlined-basic"
                                                variant="outlined"
                                                size="small"
                                                //value={polygon[index][1]}
                                                onChange={evt => handleInput(evt, index, false)}
                                                inputRef={register({ required: true })}
                                            />
                                        </div>
                                        <div className="col-1">
                                            <Button
                                                color="danger"
                                                variant="contained"
                                                onClick={() => subPoint(index)}
                                            >
                                                -
                                            </Button>
                                        </div>
                                    </div>
                                ))}
                                <div className="row mb-3">
                                    <div className="col-5">
                                        <Button
                                            fullWidth
                                            color="primary"
                                            variant="contained"
                                            onClick={() => addPoint()}
                                        >
                                            Thêm điểm
                                        </Button>
                                    </div>
                                    <div className="col-5">
                                        <Button
                                            fullWidth
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => searchPolygon()}
                                        >
                                            <SearchIcon></SearchIcon>
                                            Tìm kiếm
                                        </Button>
                                    </div></div>


                            </form>
                            <div className="row mb-3">
                                <div className="col-5">
                                    {isLisence && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            onClick={() => setIsOpenForm(true)}
                                        >
                                            Cấp phép
                                        </Button>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="list_pl_info">
                                {planningInfo && planningInfo.map((item) => (
                                    <div className="row p-3">
                                        <p className="pl_info_name">{item?.planning_name}</p>
                                        <RenderListInfo listData={JSON.parse(item.info)} />
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>

                </div>
                <div className="col-8 add-lisence-file-map">
                    <InitMapDataView
                        planningId={planningId}
                        mapId={mapId}
                        isLisence={true}
                        point={point}
                        setPoint={setPoint}
                        polygon={polygon}
                        polygonCoords={polygonCoords}
                        reload={reload}
                    />
                </div>
            </div>
            <LisenceFileForm
                setIsLisence={setIsLisence}
                setIsOpenForm={setIsOpenForm}
                isLisence={isLisence}
                isOpenForm={isOpenForm}
                polygon={polygon}
                isVN2000={isVN2000}
                polygonVN2000={polygonVN2000}
                refreshValue={refreshValue}
                setReload={setReload}
                reload={reload}
            />
        </>
    )
}

const RenderListInfo = (props) => {
    const { listData } = props;
    const [dataShow, setDataShow] = useState([])
    const handleListData = (data) => {
        if (data) {
            let res = [];
            for (let i in data) {
                data[i] !== null && i == 'dientich' && res.push({ name: 'Diện tích', value: data[i] })
                data[i] !== null && i == 'danso' && res.push({ name: 'Dân số', value: data[i] })
                data[i] !== null && i == 'cnsdd' && res.push({ name: 'Chứng nhận sdd', value: data[i] })
                data[i] !== null && i == 'loaidat' && res.push({ name: 'Loại đất', value: data[i] })
                data[i] !== null && i == 'solo' && res.push({ name: 'Số lô', value: data[i] })
                data[i] !== null && i == 'mdxd' && res.push({ name: 'Mật độ xây dựng', value: data[i] })
                data[i] !== null && i == 'tangcaomax' && res.push({ name: 'Tầng cao tối đa', value: data[i] })
                data[i] !== null && i == 'tangcaomin' && res.push({ name: 'Tầng cao tối thiểu', value: data[i] })
            }
            //console.log(res)
            setDataShow(res)
        } else return
    }
    useEffect(() => {
        handleListData(listData)
    }, [])
    return (
        <div className="pl_info_">
            {dataShow && dataShow.map((item) => (
                <div className="row">
                    <div className="col-5 pl_info_item_">
                        <p>{item.name}</p>
                    </div>
                    <div className="col-7 pl_info_item">
                        <p>{item.value}</p>
                    </div>
                </div>
            ))}
        </div>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(AddLisenceFile);