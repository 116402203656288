const env = process.env.NODE_ENV || "development";

const apiEnvironment = {
  development: {
        api: "https://thanhhoa-api.cgis.vn/",
        paht_api: "https://pahtsonla-api.cgis.asia/",
        media_url: "https://thanhhoa-api.cgis.vn/",
        domainAdminSide: "http://localhost:3000",
        domainUserSide: "https://thanhhoa.cgis.vn/",
        domainName: "",
        workSpace: "thanhhoa",
        wmsBaseLink: "https://geo.cgis.asia/geoserver/thanhhoa/wms/",
  },
    production: {
      api: "https://thanhhoa-api.cgis.vn/",
      paht_api: "https://pahtsonla-api.cgis.asia/",
      media_url: "https://thanhhoa-api.cgis.vn/",
      domainAdminSide: "https://admin.thanhhoa.cgis.vn",
      domainUserSide: "https://thanhhoa.cgis.vn/",
      domainName: "cgis.vn",
      workSpace: "thanhhoa",
      wmsBaseLink: "https://geo.cgis.asia/geoserver/thanhhoa/wms/",
  } 
};

module.exports = apiEnvironment[env];
