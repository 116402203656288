import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as appActions from "../../../core/app.store";
import InitMapDataView from "../init-map-view/init-map-view";
import * as planningUnitAction from "../../../redux/store/lisence-file/lisence-file.store";
import * as proviceAction from "../../../redux/store/province-management/province.store";
import * as districtAction from "../../../redux/store/district-management/district.store";
import NotificationService from "../../../common/notification-service";
import FileManagement from "../../../components/file_management/file_management";

// muiUi
import {
    DialogActions,
    Button,
    TextField,
    DialogContent,
    DialogTitle,
    Dialog,
    Typography,
    IconButton,
    makeStyles,
    RadioGroup,
    FormControlLabel,
    Radio,
    Tooltip,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import CloseIcon from "@material-ui/icons/Close";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./add-lisence-file.scss"
const useStyles = makeStyles((theme) => ({
    closeButton: {
      position: "absolute",
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  }));
function LisenceFileForm(props) {
    const { showLoading, isOpenForm, setIsLisence, setIsOpenForm, polygon, refreshValue } = props;
    const classes = useStyles();
    const { register, handleSubmit, errors, setValue, clearErrors, setError } =
        useForm({
            mode: "all",
            reValidateMode: "onBlur",
        });

    const [provinceModel, setProvinceModel] = useState([]);
    const [province, setProvince] = useState("");
    const [district, setDistrict] = useState("");
    const [type, setType] = useState("Cấp phép xây dựng");
    const [status, setStatus] = useState("");
    const [constructionType, setConstructionType] = useState("");
    const [constructionTier, setConstructionTier] = useState("");
    const [currentStatus, setCurrentStatus] = useState();
    const [lisenceFileStatus, setLisenceFileStatus] = useState();
    const [lisenceFileType, setLisenceFileType] = useState();
    const [districtModel, setDistrictModel] = useState([]);
    const [lisenceFileTypeModel, setLisenceFileTypeModel] = useState([]);
    const [lisenceFileCurrentStatusModel, setLisenceFileCurrentStatusModel] = useState([]);
    const [lisenceFileStatusModel, setLisenceFileStatusModel] = useState([]);
    const [constructionTypeModel, setConstructionTypeModel] = useState([]);
    const [constructionTierModel, setConstructionTierModel] = useState([]);

    const [documentSelected, setDocumentSelected] = useState([]);
    const [isDocumentShow, setDocumentShow] = useState(false);
    const [documents, setDocuments] = useState([]);
    const [documentsTemp, setDocumentsTemp] = useState([]);

    const [gallerySelected, setGallerySelected] = useState([]);
    const [isGalleryShow, setGalleryShow] = useState(false);
    const [gallery, setGallery] = useState([]);
    const [galleryTemp, setGalleryTemp] = useState([]);

    const GetLookupProvince = () => {
        showLoading(true);
        proviceAction
            .GetLookupProvince()
            .then((res) => {
                if (res && res.content) {
                    setProvinceModel(res.content);
                }
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    }

    const GetLookupDistrict = () => {
        showLoading(true);
        districtAction
            .GetLookupDistrict()
            .then((res) => {
                if (res && res.content) {
                    setDistrictModel(res.content);
                }
                showLoading(false);
            })
            .catch((err) => {
                showLoading(false);
            });
    }

    const addLisenFile = (data) => {
        console.log(data);
        let params = data;
        if(data.area == "") params.area = 0 ;
        if(data.constructionArea == "") data.constructionArea= 0 ;
        if(data.floorArea == "") data.floorArea= 0 ;
        params.coordinates = polygon;
        params.galleryIds = gallerySelected && gallerySelected.map(item => item.fileId);
        params.documentIds = documentSelected && documentSelected.map(item => item.fileId);
        CreateLisenceFile(params);
    }

    const CreateLisenceFile = (data) => {
        planningUnitAction.CreateLisenceFile(data).then((res) => {
            NotificationService.success("Thêm mới thành công");
            setIsOpenForm(false);
            refreshValue();
        }).catch(err => {
            NotificationService.error("Thêm mới không thành công, liên hệ với Admin để hỗ trợ");
            setIsOpenForm(false);
        })
    }
    useEffect(() => {
        GetLookupProvince();
        GetLookupDistrict();
        GetLookupConstructionType();
        GetLookupConstructionTier();
        GetLookupLisenceFileCurrentStatus();
        GetLookupLisenceFileStatus();
        GetLookupLisenceFileType();
        GetLookupDistrict();
    }, [])

    const GetLookupLisenceFileType = () => {
        planningUnitAction
            .GetLookupLisenceFileType()
            .then((res) => {
                if (res && res.content) {
                    setLisenceFileTypeModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupLisenceFileStatus = () => {
        planningUnitAction
            .GetLookupLisenceFileStatus()
            .then((res) => {
                if (res && res.content) {
                    setLisenceFileStatusModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupLisenceFileCurrentStatus = () => {
        planningUnitAction
            .GetLookupLisenceFileCurrentStatus()
            .then((res) => {
                if (res && res.content) {
                    setLisenceFileCurrentStatusModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupConstructionTier = () => {
        planningUnitAction
            .GetLookupConstructionTier()
            .then((res) => {
                if (res && res.content) {
                    setConstructionTierModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const GetLookupConstructionType = () => {
        planningUnitAction
            .GetLookupConstructionType()
            .then((res) => {
                if (res && res.content) {
                    setConstructionTypeModel(res.content.map((item) => {
                        return { ...item, label: item.name, value: item.id }
                    }));
                }
            })
            .catch((err) => {
            });
    }
    const onOpenSelectDocument = () => {
        setDocumentShow(true);
        setDocumentsTemp(documents);
    };
    const onCloseSelectDocument = () => {
        setDocumentShow(false);
        setDocuments(documentsTemp);
    };
    const onSaveSelectDocument = () => {
        setDocumentShow(false);
        setDocumentSelected([...documentSelected, ...documents])
    };
    const onOpenSelectGallery = () => {
        setGalleryShow(true);
        setGalleryTemp(gallery);
    };
    const onCloseSelectGallery = () => {
        setGalleryShow(false);
        setGallery(galleryTemp);
    };
    const onSaveSelectGallery = () => {
        setGalleryShow(false);
        setGallerySelected([...gallerySelected, ...gallery])
    };
    return (
        <>
            <Dialog
                maxWidth="md"
                fullWidth={true}
                onClose={() =>
                    setIsOpenForm(false)
                }
                aria-labelledby="ConstructionInvestmentDelete"
                aria-describedby="ConstructionInvestmentDelete"
                id="delete-ci"
                open={isOpenForm}
            >
                <DialogTitle id="ConstructionInvestmentDelete">Thêm mới cấp phép xây dựng</DialogTitle>
                <DialogContent>
                    <form onSubmit={handleSubmit(addLisenFile)}>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Số thửa<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="parcel"
                                    error={
                                        (errors.parcel &&
                                            errors.parcel.type === "required") ||
                                        (errors.parcel &&
                                            errors.parcel.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.parcel &&
                                    errors.parcel.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.parcel &&
                                    errors.parcel.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Số tờ<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="paperNumber"
                                    error={
                                        (errors.paperNumber &&
                                            errors.paperNumber.type === "required") ||
                                        (errors.paperNumber &&
                                            errors.paperNumber.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.paperNumber &&
                                    errors.paperNumber.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.paperNumber &&
                                    errors.paperNumber.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Chủ sở hữu<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="owner"
                                    error={
                                        (errors.owner &&
                                            errors.owner.type === "required") ||
                                        (errors.owner &&
                                            errors.owner.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.owner &&
                                    errors.owner.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.owner &&
                                    errors.owner.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Số CMND/CCCD<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="passport"
                                    error={
                                        (errors.passport &&
                                            errors.passport.type === "required") ||
                                        (errors.passport &&
                                            errors.passport.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.passport &&
                                    errors.passport.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.passport &&
                                    errors.passport.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Email
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        maxLength: 300,
                                    })}
                                    type="email"
                                    name="email"
                                    error={
                                        (errors.email &&
                                            errors.email.type === "required") ||
                                        (errors.email &&
                                            errors.email.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.email &&
                                    errors.email.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.email &&
                                    errors.email.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Số điện thoại<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="number"
                                    name="phone"
                                    error={
                                        (errors.phone &&
                                            errors.phone.type === "required") ||
                                        (errors.phone &&
                                            errors.phone.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.phone &&
                                    errors.phone.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.phone &&
                                    errors.phone.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Tỉnh
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={provinceModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={province}
                                    onChange={(event, newValue) => {
                                        setProvince(newValue);
                                        setValue("provinceId", newValue?.id)
                                        clearErrors("provinceId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="provinceId"
                                            {...register('provinceId', { required: true })}
                                            error={
                                                errors.provinceId &&
                                                errors.provinceId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.provinceId &&
                                    errors.provinceId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Huyện/ Thành Phố
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={districtModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={district}
                                    onChange={(event, newValue) => {
                                        setDistrict(newValue);
                                        setValue("districtId", newValue?.id)
                                        clearErrors("districtId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="districtId"
                                            {...register('districtId', { required: true })}
                                            error={
                                                errors.districtId &&
                                                errors.districtId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.districtId &&
                                    errors.districtId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Loại cấp phép
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={lisenceFileTypeModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={lisenceFileType}
                                    onChange={(event, newValue) => {
                                        setLisenceFileType(newValue);
                                        setValue("lisenceFileTypeId", newValue?.id)
                                        clearErrors("lisenceFileTypeId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="lisenceFileTypeId"
                                            {...register('lisenceFileTypeId', { required: true })}
                                            error={
                                                errors.lisenceFileTypeId &&
                                                errors.lisenceFileTypeId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.lisenceFileTypeId &&
                                    errors.lisenceFileTypeId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Trạng thái
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={lisenceFileStatusModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={lisenceFileStatus}
                                    onChange={(event, newValue) => {
                                        setLisenceFileStatus(newValue);
                                        setValue("lisenceFileStatusId", newValue?.id)
                                        clearErrors("lisenceFileStatusId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="lisenceFileStatusId"
                                            {...register('lisenceFileStatusId', { required: true })}
                                            error={
                                                errors.lisenceFileStatusId &&
                                                errors.lisenceFileStatusId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.lisenceFileStatusId &&
                                    errors.lisenceFileStatusId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Loại công trình xây dựng
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={constructionTypeModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={constructionType}
                                    onChange={(event, newValue) => {
                                        setConstructionType(newValue);
                                        setValue("constructionTypeId", newValue?.id)
                                        clearErrors("constructionTypeId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="constructionTypeId"
                                            {...register('constructionTypeId', { required: true })}
                                            error={
                                                errors.constructionTypeId &&
                                                errors.constructionTypeId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.constructionTypeId &&
                                    errors.constructionTypeId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Cấp xây dựng
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={constructionTierModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={constructionTier}
                                    onChange={(event, newValue) => {
                                        setConstructionTier(newValue);
                                        setValue("constructionTierId", newValue?.id)
                                        clearErrors("constructionTierId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="constructionTierId"
                                            {...register('constructionTierId', { required: true })}
                                            error={
                                                errors.constructionTierId &&
                                                errors.constructionTierId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.constructionTierId &&
                                    errors.constructionTierId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Địa chỉ<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="address"
                                    error={
                                        (errors.address &&
                                            errors.address.type === "required") ||
                                        (errors.address &&
                                            errors.address.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.address &&
                                    errors.address.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.address &&
                                    errors.address.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                Tình trạng công trình
                                    <span className="required"></span>
                                </label>
                                <Autocomplete
                                    options={lisenceFileCurrentStatusModel}
                                    getOptionLabel={(option) =>
                                        typeof option === "string" ? option : option.name
                                    }
                                    value={currentStatus}
                                    onChange={(event, newValue) => {
                                        setCurrentStatus(newValue);
                                        setValue("lisenceFileCurrentStatusId", newValue?.id)
                                        clearErrors("lisenceFileCurrentStatusId");
                                    }}
                                    disableClearable={true}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            name="lisenceFileCurrentStatusId"
                                            {...register('lisenceFileCurrentStatusId', { required: true })}
                                            error={
                                                errors.lisenceFileCurrentStatusId &&
                                                errors.lisenceFileCurrentStatusId.type === "required"
                                            }
                                            variant="outlined"
                                            size="small"
                                        />
                                    )}
                                />
                                {errors.lisenceFileCurrentStatusId &&
                                    errors.lisenceFileCurrentStatusId.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Phòng ban<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="department"
                                    error={
                                        (errors.department &&
                                            errors.department.type === "required") ||
                                        (errors.department &&
                                            errors.department.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.department &&
                                    errors.department.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.department &&
                                    errors.department.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Thẩm định<span className="required"></span>
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        required: true,
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="appraiser"
                                    error={
                                        (errors.appraiser &&
                                            errors.appraiser.type === "required") ||
                                        (errors.appraiser &&
                                            errors.appraiser.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.appraiser &&
                                    errors.appraiser.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.appraiser &&
                                    errors.appraiser.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Diện tích
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        maxLength: 300,
                                    })}
                                    type="number"
                                    name="area"
                                    error={
                                        (errors.area &&
                                            errors.area.type === "required") ||
                                        (errors.area &&
                                            errors.area.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.area &&
                                    errors.area.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.area &&
                                    errors.area.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Số chứng nhận
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        maxLength: 300,
                                    })}
                                    type="text"
                                    name="certificateNumber"
                                    error={
                                        (errors.certificateNumber &&
                                            errors.certificateNumber.type === "required") ||
                                        (errors.certificateNumber &&
                                            errors.certificateNumber.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.certificateNumber &&
                                    errors.certificateNumber.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.certificateNumber &&
                                    errors.certificateNumber.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                <label className="text-dark">
                                    Diện tích xây dựng
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        maxLength: 300,
                                    })}
                                    type="number"
                                    name="constructionArea"
                                    error={
                                        (errors.constructionArea &&
                                            errors.constructionArea.type === "required") ||
                                        (errors.constructionArea &&
                                            errors.constructionArea.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.constructionArea &&
                                    errors.constructionArea.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.constructionArea &&
                                    errors.constructionArea.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                            <div className="col-6">
                                <label className="text-dark">
                                    Diện tích sàn
                                </label>

                                <TextField
                                    fullWidth
                                    inputRef={register({
                                        maxLength: 300,
                                    })}
                                    type="number"
                                    name="floorArea"
                                    error={
                                        (errors.floorArea &&
                                            errors.floorArea.type === "required") ||
                                        (errors.floorArea &&
                                            errors.floorArea.type === "maxLength")
                                    }
                                    variant="outlined"
                                    size="small"
                                    inputProps={{ maxLength: 300 }}
                                />
                                {errors.floorArea &&
                                    errors.floorArea.type === "required" && (
                                        <span className="error">Trường này là bắt buộc</span>
                                    )}
                                {errors.floorArea &&
                                    errors.floorArea.type === "maxLength" && (
                                        <span className="error">Tối đa 300 ký tự</span>
                                    )}
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">Ghi chú</label>
                                <textarea
                                    name="note"
                                    rows="5"
                                    className="form-control"
                                    ref={register}
                                ></textarea>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">File đính kèm</label> </div>
                            <div className="col-12">
                                <div className="list__img d-flex flex-wrap mt-1">
                                    {!isDocumentShow &&
                                        documentSelected &&
                                        documentSelected.length > 0 &&
                                        documentSelected.map((item) => (
                                            <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                                <img
                                                    src={require("../../../assets/icon/default.svg")}
                                                    alt={item.fileName}
                                                    title={item.fileName}
                                                    className="img-fluid mb-2"
                                                    style={{
                                                        width: "auto",
                                                        height: "auto",
                                                        maxWidth: "100%",
                                                        maxHeight: "100%",
                                                    }}
                                                />
                                                <p className="file_name">{item.fileName.length > 15 ? (item.fileName.substring(0, 15) + '...') : item.fileName}</p>
                                                <p
                                                    className="close_x"
                                                    onClick={() =>
                                                        setDocumentSelected(documentSelected.filter(i => i !== item))
                                                    }
                                                >
                                                    &#10005;
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onOpenSelectDocument}
                                    >
                                        Chọn file
                                    </Button>
                                </div>
                            </div>
                        </div>
                        <div className="form-group row">
                            <div className="col-12">
                                <label className="text-dark">Thư viện ảnh</label> </div>
                            <div className="col-12">
                                <div className="list__img d-flex flex-wrap mt-1">
                                    {!isGalleryShow &&
                                        gallerySelected &&
                                        gallerySelected.length > 0 &&
                                        gallerySelected.map((item) => (
                                            <div key={item.fileName} className='file_item mr-3' style={{ width: "80px" }}>
                                                <img
                                                    src={require("../../../assets/icon/default.svg")}
                                                    alt={item.fileName}
                                                    title={item.fileName}
                                                    className="img-fluid mb-2"
                                                    style={{
                                                        width: "auto",
                                                        height: "auto",
                                                        maxWidth: "100%",
                                                        maxHeight: "100%",
                                                    }}
                                                />
                                                <p className="file_name">{item.fileName.length > 15 ? (item.fileName.substring(0, 15) + '...') : item.fileName}</p>
                                                <p
                                                    className="close_x"
                                                    onClick={() =>
                                                        setGallerySelected(gallerySelected.filter(i => i !== item))
                                                    }
                                                >
                                                    &#10005;
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div className="col-12">
                                <div>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onOpenSelectGallery}
                                    >
                                        Chọn file
                                    </Button>
                                </div>
                            </div>
                        </div>
                        {isDocumentShow && (
                            <Dialog
                                onClose={onCloseSelectDocument}
                                open={isDocumentShow}
                                fullWidth={true}
                                maxWidth="md"
                                className="dialog-preview-form"
                            >
                                <DialogTitle disableTypography>
                                    <Typography variant="h6">Quản lý file</Typography>
                                    <IconButton
                                        aria-label="close"
                                        className={classes.closeButton}
                                        onClick={onCloseSelectDocument}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent dividers>
                                    <FileManagement
                                        multiple={true}
                                        files={documents}
                                        fileSelected={documentSelected}
                                        setFiles={setDocuments}
                                        acceptedFiles={["doc", "docx", "txt", "pdf", "png", "jpg"]}
                                    />
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        type="button"
                                        onClick={onCloseSelectDocument}
                                        variant="contained"
                                        startIcon={<CloseIcon />}
                                    >
                                        Hủy
                                    </Button>
                                    {documents && documents.length > 0 && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            startIcon={<SaveIcon />}
                                            onClick={onSaveSelectDocument}
                                        >
                                            Lưu
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>
                        )}
                        {isGalleryShow && (
                            <Dialog
                                onClose={onCloseSelectGallery}
                                open={isGalleryShow}
                                fullWidth={true}
                                maxWidth="md"
                                className="dialog-preview-form"
                            >
                                <DialogTitle disableTypography>
                                    <Typography variant="h6">Quản lý file</Typography>
                                    <IconButton
                                        aria-label="close"
                                        className={classes.closeButton}
                                        onClick={onCloseSelectGallery}
                                    >
                                        <CloseIcon />
                                    </IconButton>
                                </DialogTitle>
                                <DialogContent dividers>
                                    <FileManagement
                                        multiple={true}
                                        files={gallery}
                                        // fileSelected={gallerySelected}
                                        setFiles={setGallery}
                                        acceptedFiles={["png", "jpg"]}
                                    />
                                </DialogContent>

                                <DialogActions>
                                    <Button
                                        type="button"
                                        onClick={onCloseSelectGallery}
                                        variant="contained"
                                        startIcon={<CloseIcon />}
                                    >
                                        Hủy
                                    </Button>
                                    {gallery && gallery.length > 0 && (
                                        <Button
                                            type="button"
                                            color="primary"
                                            variant="contained"
                                            startIcon={<SaveIcon />}
                                            onClick={onSaveSelectGallery}
                                        >
                                            Lưu
                                        </Button>
                                    )}
                                </DialogActions>
                            </Dialog>
                        )}
                        <DialogActions className="border-top">
                            <Button
                                type="button"
                                onClick={() => setIsOpenForm(false)}
                                variant="contained"
                                startIcon={<CloseIcon />}
                            >
                                Hủy
                            </Button>
                            <Button
                                type="submit"
                                onClick={() => { }}
                                style={{ marginLeft: "20px" }}
                                color="primary"
                                variant="contained"
                                startIcon={<SaveIcon />}
                            >
                                Lưu
                            </Button>
                        </DialogActions>
                    </form>
                </DialogContent>
                <DialogActions>
                </DialogActions>
            </Dialog>
        </>
    )
}

const mapStateToProps = (state) => ({
    isLoading: state.app.loading,
});

const mapDispatchToProps = (dispatch) =>
    bindActionCreators(
        {
            showLoading: appActions.ShowLoading,
        },
        dispatch
    );

export default connect(mapStateToProps, mapDispatchToProps)(LisenceFileForm);